import { AgencyToBeShared } from "../../store/types";

import async from "async";
import axios from "axios";
import { v4 } from "uuid";

import { store } from "../../store/store";
import {
  detectQueryString,
  getFileTypeFromExtension,
  getPreview,
} from "../../utilities/common-function";

import { fileUploader, uploadToS3 } from "../../store/actions";
import {
  createAgencyFolderStructure,
  uploadFileInAgencyAction,
} from "../../store/actions/agencyFile";

import ZipFileSVG from "../../assets/svg/zip-file.svg";
import PPTFileSVG from "../../assets/svg/ppt-file.svg";
import DocFileSVG from "../../assets/svg/doc-file.svg";
import AudioFileSVG from "../../assets/svg/audio-file.svg";
import VideoFileSVG from "../../assets/svg/video-file.svg";
import ImageFileSVG from "../../assets/svg/image-file.svg";
import InternetFileSVG from "../../assets/svg/internet-file.svg";
import { openToast } from "../../Toasts";

export const formatDate = (dateString: Date): string => {
  const date = new Date(dateString);

  const day = date
    .getDate()
    .toString()
    .padStart(2, "0"); // Ensures day is 2 digits
  const month = date.toLocaleString("en-US", { month: "short" }); // Short month name
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

export const formatDateForUploadedFile = (dateString) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(dateString);

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Get the day suffix (st, nd, rd, th)
  const suffix =
    day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th";

  return `${day}${suffix} ${month} ${year}`;
};

// use to find the value of he query from url
export const getDataFromParams = (url: string, toFind: string) => {
  const params = new URLSearchParams(url);
  const data = params.get(toFind);
  return data;
};

export const createShareableAgencyURL = (
  baseURL: string,
  data: AgencyToBeShared
) => {
  const { shareable_link_key } = data;

  // Construct the query parameters
  const params = new URLSearchParams({
    shareable_link_key,
  });

  // Build and return the full URL
  return `${baseURL}?${params.toString()}`;
};

export function removeFolderParameter(search) {
  // Use a regular expression to find and remove the `&folder=` parameter
  return search.replace(/&folder=[^&]*/, "");
}

export const detectProjectType = (project) => {
  switch (project.file_type) {
    case "jpeg":
    case "jpg":
    case "png":
    case "gif":
    case "ico":
    case "bmp":
    case "ps":
      // return "https://aiquire-creative-stg-backend.s3.ap-south-1.amazonaws.com" + `${project.file_url}?q=40&auto=format`;
      let url = !project.file_url.includes("gif")
        ? project.file_url +
          (detectQueryString(project.file_url) ? "&w=200" : "?w=200")
        : project.file_url || "";
      // Replace the old domain with the new domain in the URL
      // var newUrl = url.replace(oldDomain, newDomain);
      return url;
    // case "svg":
    //   return ImageFileSVG;
    case "psd":
      if (!project.psdUrl) {
        return ImageFileSVG;
      } else if (project.psdUrl.includes(".psd")) {
        return ImageFileSVG;
        // return ImageFileSVG;
      } else return project.psdUrl;
    case "svg":
      const b = project.file_url.split("/");
      b[b.length - 1] = encodeURIComponent(b[b.length - 1]);
      let urlSvg = b.join("/");

      return project.file_url;
    default:
      const fileType = getFileTypeFromExtension(project.file_type);
      switch (fileType) {
        case "audio":
          return AudioFileSVG;

        case "video":
          return VideoFileSVG;

        case "presentation":
          return PPTFileSVG;

        case "internet":
          return InternetFileSVG;

        case "zip":
          return ZipFileSVG;

        case "word":
          return DocFileSVG;

        default:
          // return project.file_url.includes("gif") ? project.file_url : project.file_url + (detectQueryString(project.file_url) ? '&w=200' : '?w=200');
          return project.file_url ? project.file_url : ImageFileSVG;
      }
  }
};

const changeThumbNail = (list: any) => {
  list.forEach((file: any) => {
    if (file.type && file.type === "folder") changeThumbNail(file.files);
    let extension: string = file.name
      .split(".")
      .pop()
      .toLowerCase();
    let link: string =
      file.type.includes("image") && extension !== "psd"
        ? file.thumbUrl
        : getPreview(file.type === "folder" ? "folder" : extension);
    if (file.thumbUrl !== link) {
      file.thumbUrl = link;
    }
  });
  return list;
};

const getConfig = (file: any, index: number) => {
  let loadedPart = 0;
  return {
    onUploadProgress: function(progressEvent: any) {
      // callback && callback();
      let percentCompleted = 0;
      if (file.size === progressEvent.total) {
        percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      } else {
        loadedPart += progressEvent.loaded;
        percentCompleted = Math.round(loadedPart / file.size);
      }
      let temp: any[] = store.getState().folders.uploadedBanners;
      let i: number = temp.length === 0 ? 0 : temp[index] ? index : temp.length;
      temp[i] = {
        title: file.name,
        rendered_file: file.thumbUrl,
        thumbnail: file.thumbUrl,
        progress: percentCompleted,
        type: file.name.includes(".psd") ? "psd" : file.type.split("/")[0],
        status: "INPROGRESS",
        parent: file.parent ? file.parent : null,
        token: file.token,
      };
    },
  };
};

const throttleRequests = (
  files: any[],
  agency_id,
  activeFolderId,
  dispatch,
  folders,
  isFolder,
  uploadedFolders
) => {
  var q = async.queue(function(task: any, callback: any) {
    task(callback);
  }, 10);
  let error: boolean = false;
  files.forEach((file: any, index: number) => {
    q.push(
      (callback: any) => {
        // There will be 2 scenario
        // 1. API is called when user cancel file
        // 2. API is not called when user cancel file(It's only in upload list)

        // We will not call API if file is cancelled by user(It's only in upload list)
        if (file.status !== "CANCELLED") {
          const config: any = getConfig(file, index);

          // Token to cancel API once it is called
          let fileToProjectToken = axios.CancelToken.source();
          file.token = fileToProjectToken;

          // Give unique name to each file so it's not replaced by other file while we upload file to S3
          let extension: string = file.name.split(".").pop();
          let file_name =
            file.name.slice(0, file.name.lastIndexOf(extension) - 1) +
            v4() +
            "." +
            extension;
          const file_size = file.size;
          // Upadting cancel token as soon as API is called
          dispatch(
            fileUploader(
              {
                file_name,
                file_size,
                file,
                config,
                cancelToken: fileToProjectToken,
              },
              (fileUploaderRes: any, error: boolean) => {
                if (!error) {
                  dispatch(
                    uploadToS3(
                      {
                        file_url: fileUploaderRes.uploadURL,
                        file,
                        cancelToken: fileToProjectToken,
                      },
                      (S3res: any, error: boolean) => {
                        if (!error) {
                          // if (!!isFolder) {
                          // const addFolderPayload = {
                          //     name: folders[0].name,
                          //     agency_id,
                          //     parent: activeFolderId,
                          //   };

                          //   dispatch(createNewFolder(addFolderPayload));
                          // } else {
                          dispatch(
                            uploadFileInAgencyAction({
                              file: fileUploaderRes.file_path,
                              agency_id,
                              folder: file.parent.id || activeFolderId,
                              title: file.name,
                              file_type: extension,
                              isFolder,
                              folders: uploadedFolders,
                            })
                          );
                          // }
                        } else {
                          callback &&
                            callback(true, {
                              S3res,
                              file,
                              index,
                              error: true,
                            });
                        }
                      },
                      config
                    )
                  );

                } else {
                  callback &&
                    callback(true, {
                      fileUploaderRes,
                      file,
                      index,
                      error: true,
                    });
                }
              }
            )
          );
        } else {
          let response = "cancelled";
          callback &&
            callback(true, { res: response, file, index, error: true });
        }
      },
      function(err: any, data: any) {
      }
    );
  });
};

export const handleSubmit = (
  fileList,
  activeFolderId,
  agency_id,
  dispatch,
  isFolder = false
) => {
  let folders: any = fileList.filter((f: any) => f.type === "folder");
  Promise.all(
    folders.map((folder: any, index: number) => {
      return new Promise((res: any, rej: any) => {
        dispatch(
          createAgencyFolderStructure(
            {
              structure: folder.structure,
              agency_id,

              parent_id: activeFolderId || undefined,
            },
            (response: any, err: boolean) => {
              if (!err) {
                response.data[
                  Object.keys(response.data)[0]
                ].subfolder_count = Object.keys(
                  folder.structure[folder.name]
                ).length;
                // let idToFolderName:any = {};
                // let idToFolder:any = {};
                // response.data.map((val:any)=>{
                //     idToFolderName = {...idToFolderName,[val.id]: val.name};
                //     idToFolder = {...idToFolder,[val.id]: val};
                // })
                res({
                  data: response.data,
                  files: folder.files,
                  type: "folder",
                  name: "",
                  hashedStructure: folder.hashedStructure,
                  root: response.data[folder.name],
                });
              } else {
                rej(response);
              }
            }
          )
        );
      });
    })
  )
    .then((res: any) => {
      let rootFolders: any[] = res.reduce(
        (acc: any, folder: any) => [
          ...acc,
          { ...folder.root, parent: activeFolderId },
        ],
        []
      );
      // Sorting based on folder id
      rootFolders = rootFolders.sort(function(a, b) {
        return a.id - b.id;
      });
      // dispatch(addFolder(rootFolders));
      let currentFolderFiles: number = 0;
      let uploadedFolders: any[] = [];
      let list: any = fileList.reduce((acc: any, file: any) => {
        let val: any = [];
        if (file.type === "folder") {
          let folderData: any = res.shift();
          let folders: any = folderData?.hashedStructure;
          let hashedStructureFolderIds: any[] = Object.keys(
            folderData.data
          ).reduce(
            (acc: number[], key: any) => [...acc, folderData.data[key].id],
            []
          );
          let folderMap: any = folderData?.data;
          let folderFiles: any = [];
          let folderKeys: any = Object.keys(folders || {});
          folderKeys.map((key: any, index: number) => {
            folders[key].map((f: any) => {
              f.file["parent"] = folderMap
                ? {
                    ...folderMap[key],
                    parent: { ...folderData.root, parent: activeFolderId },
                  }
                : null;
              folderFiles.push(f.file);
            });
          });
          folderData.root.totalCount = folderData.files.length;
          folderData.root.hashedStructureFolderIds = hashedStructureFolderIds;
          uploadedFolders = uploadedFolders.concat(folderData.root);
          val = folderFiles || file.files;
        } else {
          currentFolderFiles = currentFolderFiles + 1;
          val = [file];
        }
        return [...acc, ...val];
      }, []);
      // dispatch(setUploadedBanners([]))
      if (currentFolderFiles > 0) {
        let folder = {
          id: activeFolderId === 0 ? null : activeFolderId,
          totalCount: currentFolderFiles,
          hashedStructureFolderIds: [
            activeFolderId === 0 ? null : activeFolderId,
          ],
        };
        uploadedFolders = uploadedFolders.concat(folder);
      }
      // Sorting based on folder id
      uploadedFolders = uploadedFolders.sort(function(a, b) {
        return a.id - b.id;
      });
      throttleRequests(
        changeThumbNail(list),
        agency_id,
        activeFolderId,
        dispatch,
        folders,
        isFolder,
        uploadedFolders
      );
    })
    .catch((res: any) => {
      console.log(res, "res");
    });
};

export const buildParentHierarchyJson = (breadcrumbsData, foldersList) => {
  // Map to keep track of folder details by their IDs
  const folderMap = {};

  // Populate folderMap with { id, name } pairs from breadcrumbsData
  breadcrumbsData.forEach((item) => {
    const folderId = parseInt(item.folder, 10);
    const parent = item.data?.parent;
    if (parent && folderId) {
      folderMap[folderId] = { id: parent.id, name: parent.name };
    }
  });

  // Create the array of JSON objects following the foldersList order
  return foldersList
    ?.map((folderId) => folderMap[folderId]) // Map folder IDs to their corresponding JSON
    .filter(Boolean); // Remove undefined entries if any folderId doesn't match
};
