export const getTemplateSizesAPI = '/api/preset-sizes/category';
export const uploadFileAPI = '/api/file';
export const getPopularTemplateSizesAPI = '/api/preset-sizes/popular';
export const getTemplatesAPI = '/api/template/'; // add params ?page=1&type=image&preset_sizes=1,2,3&custom_sizes=(320,50),(728,90),(300,250) etc
export const getCategoriesAPI = '/api/template/categories';
export const getCategoriesAllAPI = '/api/category';


export const appAPI = '/app';
export const tagsAPI = '/api/tags';
export const tagsRecommendationAPI = '/api/tags-recommendation';
export const projectAPI = '/api/project';
export const hyperProjectsAPI = '/api/hp-project';
export const templateAPI = '/api/mytemplate';
export const approvalWorkflowAPI = '/api/workflow';
export const updateApprovalRoundsAPI = '/api/workflow_entity';
export const approvalsAPI = '/api/approvals';
export const approvalCommentsAPI = '/api/approval-comment';
export const approvalStatusSummary = '/api/approval-status-summary';
export const approvalStatus = '/api/approval-status';
export const approvalRequest = '/api/request-approval';
export const folderApprovalAPI = '/api/multiple-request-approval';
export const approvalRequestAgain = '/api/request-approval-again';
export const pendingApproval = '/api/pending-approval';
export const pendingApprovalCount = '/api/pending-approval-count';
export const saveMultipleTemplatesAPI = '/api/project/'; // add params
export const createProjectAPI = '/api/project/new';
export const reportingLoginAPI = '/api/auth/servicelogin';
export const updateProjectAPI = '/api/project/'; // add projectId
export const getProjectAPI = '/api/project/'; // add projectId
export const getTemplateDataAPI = '/api/template/'; // add template id
export const getCategoriesOfElementsAPI = '/api/element/category/'; // add params
export const getElementsOfCategoryAPI = '/api/element/'; // add params
export const deleteMultipleFilesAPI = '/api/file/delete/';
export const getUploadedFilesAPI = '/api/file'; // add params
export const getPSDJSONAPI = '/conversion/mutations/psd-to-json-new';
export const getFontsListAPI = '/api/typography'; // add params
export const getSubFoldersAPI = '/api/folder'; // add params
export const getHyperProjectAPI = '/api/hp-project/'; // add project id
export const updateHyperProjectDetailsAPI = '/api/hp-project/'; // add project id
export const getLayerAssetsAPI = '/api/hp-project/'; // add project id
export const getAssetLayerDataAPI = '/api/hp-project/'; // add params
export const updateAssetLayerDataAPI = '/api/hp-project/'; // add params
export const getAssetsCombinationDataAPI = '/api/hp-project/'; // add params
export const updateAssetsCombinationDataAPI = '/api/hp-project/'; // add params
export const updateAssetLayerDataBulkAPI = '/api/hp-project/' // add params
export const generateCombinationsAPI = '/api/hp-project/' // add params
export const addAssetsAPI = '/api/hp-project/' // add params
export const deleteAssetAPI = '/api/hp-project/' // add params
export const renderImagesAPI = '/api/project/' // add params
export const exportAPI = '/api/export'
export const exportOriginal = '/api/export/original'
export const renameHyperLayerAPI = '/api/hp-project/'; // add params
export const getUrlStockImageAPI = '/api/stock-image'
export const projectDescription = '/api/project-description';
export const designMetaAPI = '/api/meta'; // deisgn meta 
export const designMetaRecommendataionAPI = '/api/meta/recommendation'; // deisgn meta 
export const agenciesAPI = '/api/agencies/' // add params;
export const agenciesFoldersAPI = '/api/agency-folders/' // add params;
export const agenciesFilesAPI = '/api/agency-files/' // add params;
