import { call, put, takeEvery } from "redux-saga/effects";
import {
  CREATE_NEW_AGENCY,
  DELETE_AGENCY,
  FETCH_AGENCY_LIST,
  DeleteAgency,
  FetchAgencyList,
  FETCH_AGENCY_FOLDERS,
  FetchAgencyFolders,
  CREATE_NEW_FOLDER,
  CreateNewFolder,
  FETCH_AGENCY_DATA,
  DELETE_AGENCY_FOLDER,
  DeleteAgencyFolder,
  RENAME_AGENCY_FOLDER,
  RenameAgencyFolder,
  GET_AGENCY_SHAREABLE_KEY,
  GetAgencyShareableKey,
  POST_AGENCY_SHAREABLE_KEY,
  PostAgencyShareableKey,
  GET_AGENCY_FILES_LIST,
  GetAgencyFilesList,
  UPLOAD_FILE_IN_AGENCY,
  UploadFileInAgencyAction,
  CREATE_AGENCY_FOLDER_STRUCTURE,
  CreateAgencyFolderStructure,
  DELETE_FOLDER_FILES,
  DeleteFolderFiles,
  FETCH_DAM_FOLDERS,
  FetchDAMFoldersAction,
  COPY_FILES_AND_FOLDERS_TO_DAM_ACTION,
  CopyFileAndFoldersToDamAction,
  DOWNLOAD_FOLDER_ACTION,
  DownloadFolderAction,
} from "../types";
import HttpService from "../../HttpService/HttpService";
import { store } from "../store";
import { DESIGN_URI } from "../../utilities/paths";
import {
  agenciesAPI,
  agenciesFilesAPI,
  agenciesFoldersAPI,
  getSubFoldersAPI,
} from "../../MappedAPI/MappedAPI";
import { openToast } from "../../Toasts";
import {
  addNewAgency,
  addNewAgencyFolder,
  fetchAgencyListAction,
  setAgencyFolders,
  getAgencyList,
  fetchAgencyData,
  setAgencyData,
  setAgencyShareableKey,
  stopLoadingOnErrorAction,
  setCurrentAgencyFolderDataBreadcrumbsAction,
  setUploadFileInAgencyAction,
  setDAMFoldersAction,
} from "../actions/agencyFile";
import { getUrlProperties } from "../../utilities/common-function";
import { getDataFromParams } from "../../Pages/AgencyFileDrop/utils";

function* fetchAgencyList(action: FetchAgencyList) {
  let { workspace, page } = action.payload;
  const TOKEN: any = store.getState().authentication.token;
  let shouldLoading: boolean = false;
  try {
    let params = `?workspace=${workspace}&page=${page ? page : 1}&page_size=11`;
    let response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${agenciesAPI}${params}`,
      TOKEN
    );
    if (!response.data.error) {
      // const { search } = getUrlProperties();
      // const selectedAgencyId = getDataFromParams(search, "agency");
      // const isAgencyExist = response.data.data.results.some(
      //   (agency) => String(agency.id) === selectedAgencyId
      // );
      // if (!isAgencyExist && !page && !!selectedAgencyId) {
      //   yield put(fetchAgencyData(selectedAgencyId));
      //   shouldLoading = true;
      // }
      yield put(
        getAgencyList({
          data: response.data.data,
          page,
          shouldLoading,
        })
      );
    }
  } catch (error) {
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
    yield put(stopLoadingOnErrorAction());
  }
}

function* createNewAgencySaga(action: FetchAgencyList) {
  let { payload } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let response = yield call(
      HttpService.post,
      DESIGN_URI,
      `${agenciesAPI}new`,
      TOKEN,
      payload
    );
    if (!response.data.error) {
      yield put(addNewAgency(response.data.data));
    }
  } catch (error) {
    yield put(stopLoadingOnErrorAction());
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* fetchAgencyDataSaga(action: FetchAgencyFolders) {
  let { payload } = action;
  const TOKEN: any = !!store.getState().agencyPublicLink.public_access_token
    ? store.getState().agencyPublicLink.public_access_token
    : store.getState().authentication.token;
  try {
    let response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${agenciesAPI}${payload}`,
      TOKEN
    );
    if (!response.data.error) {
      yield put(setAgencyData(response.data.data));
    }
  } catch (error) {
    yield put(stopLoadingOnErrorAction());
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* deleteAgencySaga(action: DeleteAgency) {
  let { id, workspace } = action.payload;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let response = yield call(
      HttpService.delete,
      DESIGN_URI,
      `${agenciesAPI}${id}/delete/`,
      TOKEN
    );
    if (!response.data.error) {
      yield put(fetchAgencyListAction({ workspace: workspace }));
    }
  } catch (error) {
    yield put(stopLoadingOnErrorAction());
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* fetchAgencyFoldersSaga(action: FetchAgencyFolders) {
  let { agency_id, page, parent, folderToBeRemoved } = action.payload;
  const TOKEN: any = !!store.getState().agencyPublicLink.public_access_token
    ? store.getState().agencyPublicLink.public_access_token
    : store.getState().authentication.token;
  try {
    let params = `?type=project&agency_id=${agency_id}${
      parent ? `&parent=${parent}` : ""
    }&page=${page ? page : 1}&page_size=33`;
    let response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${agenciesFoldersAPI}${params}`,
      TOKEN
    );
    if (!response.data.error) {
      yield put(
        setAgencyFolders({
          data: response.data.data,
          page,
          parent,
          folderToBeRemoved,
        })
      );
    }
  } catch (error) {
    yield put(stopLoadingOnErrorAction());
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* createNewAgencyFolderSaga(action: CreateNewFolder) {
  let { payload } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let requiredPayload = {
      ...payload,
      type: "project",
      product_id: null,
      parent_id: !!payload.parent ? payload.parent : null,
    };
    let response = yield call(
      HttpService.post,
      DESIGN_URI,
      `${agenciesFoldersAPI}new/`,
      TOKEN,
      requiredPayload
    );
    if (!response.data.error) {
      yield put(addNewAgencyFolder(response.data.data));
    }
  } catch (error) {
    yield put(stopLoadingOnErrorAction());
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* deleteAgencyFolderSaga(action: DeleteAgencyFolder) {
  let { id, agencyId } = action.payload;
  const TOKEN: any = store.getState().authentication.token;
  try {
    const requiredPayload = { folder: [id], agencyId };
    yield call(
      HttpService.delete,
      DESIGN_URI,
      `${agenciesFoldersAPI}trash/`,
      TOKEN,
      requiredPayload
    );
  } catch (error) {
    yield put(stopLoadingOnErrorAction());
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* renameAgencyFolderSaga(action: RenameAgencyFolder) {
  let { id, name } = action.payload;
  const TOKEN: any = store.getState().authentication.token;
  try {
    yield call(
      HttpService.put,
      DESIGN_URI,
      `${agenciesFoldersAPI}${id}`,
      TOKEN,
      { name }
    );
  } catch (error) {
    yield put(stopLoadingOnErrorAction());
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* getAgencyShareableKeySaga(action: GetAgencyShareableKey) {
  let { agency } = action.payload;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${agenciesAPI}${agency}/share`,
      TOKEN
    );
    if (!response.data.error) {
      yield put(setAgencyShareableKey(response.data.data));
    }
  } catch (error) {
    yield put(stopLoadingOnErrorAction());
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* postAgencyShareableKeySaga(action: PostAgencyShareableKey) {
  let { password_flag, agency } = action.payload;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let response = yield call(
      HttpService.post,
      DESIGN_URI,
      `${agenciesAPI}${agency}/share?password_flag=${password_flag}`,
      TOKEN,
      { password_flag }
    );
    if (!response.data.error) {
      yield put(setAgencyShareableKey(response.data.data));
    }
  } catch (error) {
    yield put(stopLoadingOnErrorAction());
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* getAgencyFilesListSaga(action: GetAgencyFilesList) {
  let { agency_id, folders, page } = action.payload;
  const TOKEN: any = !!store.getState().agencyPublicLink.public_access_token
    ? store.getState().agencyPublicLink.public_access_token
    : store.getState().authentication.token;
  try {
    let params = `?agency_id=${agency_id}&page=${
      page ? page : 1
    }&page_size=25&folder=${folders}`;
    let response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${agenciesFilesAPI}${params}`,
      TOKEN
    );

    if (!response.data.error) {
      yield put(
        setCurrentAgencyFolderDataBreadcrumbsAction({
          data: response.data.data,
          folder: folders,
          page,
        })
      );
    }
  } catch (error) {
    yield put(stopLoadingOnErrorAction());
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* uploadFilesInAgencySaga(action: UploadFileInAgencyAction) {
  const {
    file,
    agency_id,
    folder,
    title,
    file_type,
    isFolder,
    folders,
  } = action.payload;
  const TOKEN: any = !!store.getState().agencyPublicLink.public_access_token
    ? store.getState().agencyPublicLink.public_access_token
    : store.getState().authentication.token;

  const formData = new FormData();
  // Append required fields to FormData
  formData.append("file", file);
  formData.append("agency_id", agency_id);
  formData.append("folder", folder);
  formData.append("title", title);
  formData.append("type", "direct");
  formData.append("file_type", file_type);
  try {
    let response = yield call(
      HttpService.post,
      DESIGN_URI,
      `${agenciesFilesAPI}`,
      TOKEN,
      formData
    );
    if (!response.data.error) {
      yield put(
        setUploadFileInAgencyAction({
          data: response.data.data,
          isFolder,
          folders,
        })
      );

    }
  } catch (error) {
    yield put(stopLoadingOnErrorAction());
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* createAgencyFolderStructureSaga(action: CreateAgencyFolderStructure) {
  const {
    payload: { structure, agency_id, parent_id },
    callback,
  } = action;
  const TOKEN: any = store.getState().agencyPublicLink.public_access_token;

  try {
    let response = yield call(
      HttpService.post,
      DESIGN_URI,
      `${agenciesFoldersAPI}create-structure/`,
      TOKEN,
      {
        structure,
        agency_id,
        type: "project",
        product_id: null,
        parent_id,
      }
    );

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* deleteFolderFilesSaga(action: DeleteFolderFiles) {
  let { id } = action.payload;
  const TOKEN: any = store.getState().authentication.token;
  try {
    yield call(
      HttpService.delete,
      DESIGN_URI,
      `${agenciesFilesAPI}trash/`,
      TOKEN,
      { file: [id] }
    );
  } catch (error) {
    yield put(stopLoadingOnErrorAction());
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* fetchDAMFoldersSaga(action: FetchDAMFoldersAction) {
  const { product_id, parent } = action.payload;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = !!parent
      ? `?parent=${parent}&type=project&product_id=${product_id}`
      : `?type=project&product_id=${product_id}`;
    let response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${getSubFoldersAPI}${params}`,
      TOKEN
    );
    if (!response.data.error) {
      yield put(setDAMFoldersAction(response.data.data));
    }
  } catch (error) {
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
    yield put(stopLoadingOnErrorAction());
  }
}

function* copyFileAndFoldersToDamSaga(action: CopyFileAndFoldersToDamAction) {
  let { items, product_id, foldersArray, isFolder } = action.payload;
  const TOKEN: any = store.getState().authentication.token;
  try {
    const isHavingNewFolder = foldersArray.filter(
      (folder) => folder?.isNewFolder === true
    );
    const requiredPayload = {
      product_id,
      destination_folder_id: !!foldersArray.length
        ? String(foldersArray[foldersArray.length - 1].id)
        : null,
      [isFolder ? "folder_ids" : "file_ids"]: [`${items}`],
    };

    let response = yield call(
      HttpService.post,
      DESIGN_URI,
      !!isFolder
        ? `${agenciesFoldersAPI}move-to-product/`
        : `${agenciesFilesAPI}move-to-product/`,
      TOKEN,
      requiredPayload
    );
    if (!response.data.error) {
      yield put(stopLoadingOnErrorAction());
    }
  } catch (error) {
    yield put(stopLoadingOnErrorAction());
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* downloadFolderSaga(action: DownloadFolderAction) {
  let { agency_id, folder_id } = action.payload;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `${agency_id}/${folder_id}/download/`;
    let response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${agenciesFoldersAPI}${params}`,
      TOKEN
    );
    if (response && response.data) {
      console.log(
        "Response downloadFolderSaga data size:",
        response.data.byteLength
      );
      console.log("Response downloadFolderSaga headers:", response.headers);
      const filename = "shubham.zip";

      // Create a Blob with the correct MIME type for zip
      const blob = new Blob([response.data], { type: "application/zip" });

      // Create a link element to trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      console.log(
        "🚀 ~ function*downloadFolderSaga ~ blob:",
        blob,
        "=======url========",
        url
      );
      // Append link to body and trigger click
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  } catch (error) {
    console.log("🚀 ~ function*downloadFolderSaga ~ error:", error);
    yield put(stopLoadingOnErrorAction());
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

export function* agenciesWatcher() {
  yield takeEvery(FETCH_AGENCY_LIST, fetchAgencyList);
  yield takeEvery(CREATE_NEW_AGENCY, createNewAgencySaga);
  yield takeEvery(DELETE_AGENCY, deleteAgencySaga);
  yield takeEvery(FETCH_AGENCY_FOLDERS, fetchAgencyFoldersSaga);
  yield takeEvery(CREATE_NEW_FOLDER, createNewAgencyFolderSaga);
  yield takeEvery(FETCH_AGENCY_DATA, fetchAgencyDataSaga);
  yield takeEvery(DELETE_AGENCY_FOLDER, deleteAgencyFolderSaga);
  yield takeEvery(RENAME_AGENCY_FOLDER, renameAgencyFolderSaga);
  yield takeEvery(GET_AGENCY_SHAREABLE_KEY, getAgencyShareableKeySaga);
  yield takeEvery(POST_AGENCY_SHAREABLE_KEY, postAgencyShareableKeySaga);
  yield takeEvery(GET_AGENCY_FILES_LIST, getAgencyFilesListSaga);
  yield takeEvery(UPLOAD_FILE_IN_AGENCY, uploadFilesInAgencySaga);
  yield takeEvery(
    CREATE_AGENCY_FOLDER_STRUCTURE,
    createAgencyFolderStructureSaga
  );
  yield takeEvery(DELETE_FOLDER_FILES, deleteFolderFilesSaga);
  yield takeEvery(FETCH_DAM_FOLDERS, fetchDAMFoldersSaga);
  yield takeEvery(
    COPY_FILES_AND_FOLDERS_TO_DAM_ACTION,
    copyFileAndFoldersToDamSaga
  );
  yield takeEvery(DOWNLOAD_FOLDER_ACTION, downloadFolderSaga);
}
