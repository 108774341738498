import React, { FC, useCallback, useEffect, useRef, useState } from "react";

interface UplaodFilesOrFolderModalProps {
    isModalOpen: boolean;
    setModalOpen: (isOpen: boolean) => void;
    uploadType: string;
    setUploadType: Function;
  }

  const UplaodFilesOrFolderModal: FC<UplaodFilesOrFolderModalProps> = ({
    isModalOpen,
    setModalOpen,
    uploadType,
    setUploadType,
  }) => {
   
  
    return (
      <></>
    );
  };
  
  export default UplaodFilesOrFolderModal;