import {
  SET_AGENCY_PUBLIC_USER,
  SetAgencyPublicUser,
  VALIDATE_AGENCY_LINK,
  VALIDATE_AGENCY_LINK_PASSWORD,
  ValidateAgencyLink,
  ValidateAgencyLinkPassword,
} from "../types";

export const validateAgencyLink = (payload: {
  linkId: string;
}): ValidateAgencyLink => ({
  type: VALIDATE_AGENCY_LINK,
  payload,
});

export const setAgencyPublicUser = (payload: any): SetAgencyPublicUser => ({
  type: SET_AGENCY_PUBLIC_USER,
  payload,
});

export const validateAgencyLinkPassword = (payload: {
  linkId: string;
  password?: string;
}): ValidateAgencyLinkPassword => ({
  type: VALIDATE_AGENCY_LINK_PASSWORD,
  payload,
});
