export const VALIDATE_AGENCY_LINK = "VALIDATE_AGENCY_LINK";
export const SET_AGENCY_PUBLIC_USER = "SET_AGENCY_PUBLIC_USER";
export const VALIDATE_AGENCY_LINK_PASSWORD = "VALIDATE_AGENCY_LINK_PASSWORD";

export type PublicAgencyLinkState = {
  passwordRequired: boolean;
  valid: boolean;
  public_access_token: string;
  agency_id: string;
  expiration_date: string;
  created_by: string;
};

export type ValidateAgencyLink = {
  type: typeof VALIDATE_AGENCY_LINK;
  payload: {
    linkId: string;
  };
};

export type SetAgencyPublicUser = {
  type: typeof SET_AGENCY_PUBLIC_USER;
  payload: PublicAgencyLinkState;
};

export type ValidateAgencyLinkPassword = {
  type: typeof VALIDATE_AGENCY_LINK_PASSWORD;
  payload: {
    linkId: string;
    password?: string;
  };
};
