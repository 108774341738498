import { isEmpty } from "lodash";

export const getTreeStructure = (files:any) => {

    let fileList:any = files.map((file:any) => { return {path: file.webkitRelativePath, filename: file.name , file: file} });
    
    const hierarchy:any = {}; // {folder_name} = { name: <name of folder>, children: {...just like hierarchy...}, files: [] }
    // build tree
    fileList.map((file:any) => {
        const paths = file.path.split('/').slice(0, -1);
        let parentFolder:any = null;
        // builds the hierarchy of folders.
        paths.map((path:any) => {
            if (!parentFolder) {
                if (!hierarchy[path]) {
                    hierarchy[path] = {
                        name: path,
                        children: {},
                        files: [],
                    };
                }
                parentFolder = hierarchy[path]
            } else {
                if (!parentFolder.children[path]) {
                    parentFolder.children[path] = {
                        name: path,
                        children: {},
                        files: [],
                    };
                }
                parentFolder = parentFolder.children[path];
            }
        });
        parentFolder.files.push(file);
    });

    return hierarchy;
}

export const getStructure = (obj: any, val: any, hashedVal: any, root: string) => {
  if (!obj || typeof obj !== 'object') return { structure: val, hashedStructure: hashedVal };

  Object.keys(obj)?.forEach((key: string) => {
    if (!key) return; // Skip if the key is undefined or null
    const trimmedKey = key.trim();

    // Ensure `val` is initialized properly
    if (!val) val = {};
    val[trimmedKey] = obj[key]?.children ? {} : null;

    // Update `hashedVal` with the current path and files
    hashedVal[root + (root ? '/' : '') + trimmedKey] = obj[key]?.files;

    // Recursively process children
    if (typeof obj[key] === 'object') {
      const nextObj = obj[key]?.children ? obj[key]?.children : obj[key];
      const nextRoot = root + (root ? '/' : '') + trimmedKey;

      // Ensure `val[trimmedKey]` is initialized before passing
      if (!val[trimmedKey]) val[trimmedKey] = {};
      getStructure(nextObj, val[trimmedKey], hashedVal, nextRoot);
    }
  });

  return { structure: val, hashedStructure: hashedVal };
};


export function getFilesWebkitDataTransferItems(dataTransferItem:any) {
    function traverseFileTreePromise(item:any, path='') {
      return new Promise( resolve => {
        if (item.isFile) {
          item.file((file:any) => {
              let f:any = {
                  name: file.name,
                  originFileObj: file,
                  webkitRelativePath: path + file.name,
                  lastModified: file.lastModified,
                    lastModifiedDate: file.lastModifiedDate,
                    size: file.size,
                    type: file.type
              }
            files.push(f)
            resolve(f)
          })
        } else if (item.isDirectory) {
          let dirReader = item.createReader()
          dirReader.readEntries((entries:any) => {
            let entriesPromises = []
            for (let entr of entries)
              entriesPromises.push(traverseFileTreePromise(entr, path + item.name + "/"))
            resolve(Promise.all(entriesPromises))
          })
        }
      })
    }
  
    let files:any = []
    return new Promise((resolve, reject) => {
      let entriesPromises = []
        entriesPromises.push(traverseFileTreePromise(dataTransferItem))
      Promise.all(entriesPromises)
        .then(entries => {
          resolve(files)
        })
    })
}

export const getThumbNail = (file:any) => {
    return new Promise((res:any,rej:any)=>{
        file['thumbUrl'] = URL.createObjectURL(file.originFileObj || file);
        res(file)
    })
}