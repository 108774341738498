import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Menu, MenuItem, Dropdown } from "pyxis-ui-kit";
import { uniqueId } from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { getDataFromParams, handleSubmit } from "../../../AgencyFileDrop/utils";

import { getUrlProperties } from "../../../../utilities/common-function";

import fileIcon from "../../../../assets/svg/file-upload.svg";
import folderIcon from "../../../../assets/svg/folder-upload.svg";
import uploadIcon from "../../../../assets/svg/uploadFolderIcon.svg";
import {
  AgencyFileDropState,
  PublicAgencyLinkState,
} from "../../../../store/types";
import {
  getStructure,
  getThumbNail,
  getTreeStructure,
} from "../../../../components/shared/UploadDesignsDrawer/utils";
import UplaodFilesOrFolderModal from "../UplaodFilesOrFolderModal";

const UploadFilesAndFolderForAgency = () => {
  const dispatch = useDispatch();
  const state = useSelector(
    (state: {
      agencyPublicLink: PublicAgencyLinkState;
      agencyFileDrop: AgencyFileDropState;
    }) => ({
      ...state.agencyPublicLink,
      ...state.agencyFileDrop,
    })
  );
  const { agency_id } = state;
  const { search } = getUrlProperties();

  const folder = getDataFromParams(search, "folder");
  const foldersList = folder.split("-");
  const folderUpload = useRef<any>(null);
  const fileUpload = useRef<any>(null);
  const [activeDropdown, setActiveDropdown] = useState<boolean>(false);
      const [fileList,setFileList] = useState<any[]>([]);
      const [uploadType, setUploadType] = useState("");
      const [isUploadModalVisible, setIsUploadModalVisible] = useState<boolean>(false)

  useEffect(() => {
    let node: any = folderUpload.current;
    if (node) {
      node.directory = true;
      node.webkitdirectory = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAgencyUploadClick = () => {
    setActiveDropdown(!activeDropdown);
  };

  const handleUploadFileClick = () => {
    // setUploadType("file")
    // setIsUploadModalVisible(true);
    fileUpload.current?.click();
    setActiveDropdown(false);
  };

  const handleChange = useCallback(
    (e: any) => {
      let files: any[] = Array.from(e?.target?.files || []); // Convert FileList to an array

      for (let i = 0; i < files.length; i++) {
        files[i].uid = uniqueId("file_");
        files[i].thumbUrl = URL.createObjectURL(files[i]);
        files[i].parent = 0;
      }

      handleSubmit(files, foldersList[0], agency_id, dispatch);
      setActiveDropdown(false);
    },
    [agency_id, dispatch, foldersList]
  );

  const handleFileChange = useCallback(
    (e: any) => {
      let files: any[] = Array.isArray(e) ? e : Array.from(e?.target?.files);
      // Removing hidden files
      files = files.filter((file: any) => !file.name.startsWith("."));
      if ((Array.isArray(e) && e) || e?.target?.files?.length) {
        Promise.all(files.map(getThumbNail)).then((updatedFiles: any) => {
          let tree: any = getTreeStructure(files);
          let { structure, hashedStructure }: any = getStructure(
            tree,
            {},
            {},
            ""
          );
          let rootFolder: string = Object.keys(tree)[0];
          let size: number = files.reduce(
            (acc: any, f: any) => f.size + acc,
            0
          );

          const requiredFiles = [
            {
              name: rootFolder,
              size,
              type: "folder",
              file: tree,
              files,
              structure,
              hashedStructure,
              uid: uniqueId("folder_"),
            },
          ];

          handleSubmit(
            requiredFiles,
            foldersList[0],
            agency_id,
            dispatch,
            true
          );
          setActiveDropdown(false);
        });
      }
    },
    [agency_id, dispatch, foldersList]
  );

  const menu = () => (
    <Menu
      style={{
        width: "100%",
        borderRadius: "1rem",
        borderColor: "#E0E3E5",
      }}
    >
      <MenuItem
        key="uploadFile"
        className="dropdown__item"
        onClick={handleUploadFileClick}
      >
        <img src={fileIcon} alt="File icon" />
        Upload files
      </MenuItem>
      <MenuItem
        key="uploadFolder"
        className="dropdown__item"
        onClick={() => {
          folderUpload.current.click();
          setActiveDropdown(false);
        }}
      >
        <img src={folderIcon} alt="Folder icon" />
        Upload folder
      </MenuItem>
    </Menu>
  );
  return (
    <>
    <div style={{ margin: "0rem 2rem" }}>
      <Dropdown
        overlay={menu}
        visible={activeDropdown}
        trigger={["click"]} // Opens dropdown on click
        placement="bottomRight"
        onVisibleChange={handleAgencyUploadClick}
      >
        <Button
          btnsize="sm"
          className="agency-header__button agency-header__button--share"
          onClick={handleAgencyUploadClick}
        >
          <span className="agency-header__button-text agency-header__button--share_text">
            <img src={uploadIcon} alt="delete agency" />
            &nbsp;&nbsp;Upload
          </span>
        </Button>
      </Dropdown>

      <input
        ref={fileUpload}
        multiple
        type="file"
        style={{ display: "none" }}
        onClick={(e: any) => (e.target.value = null)}
        onChange={handleChange}
      />
      <input
        ref={folderUpload}
        multiple
        type="file"
        style={{ display: "none" }}
        onClick={(e: any) => (e.target.value = null)}
        onChange={handleFileChange}
      />
    </div>
    <UplaodFilesOrFolderModal isModalOpen={isUploadModalVisible} setModalOpen={setIsUploadModalVisible} uploadType={uploadType}  setUploadType={setUploadType}/>
    </>
  );
};

export default UploadFilesAndFolderForAgency;
