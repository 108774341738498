import {
  PublicAgencyLinkState,
  SET_AGENCY_PUBLIC_USER,
  SetAgencyPublicUser,
} from "../types/agencyPublicLink";

const initialState: PublicAgencyLinkState = {
  passwordRequired: false,
  valid: false,
  public_access_token: "",
  agency_id: "",
  expiration_date: "",
  created_by: "",
};

export const publicAgencyLinkReducer = (
  state: PublicAgencyLinkState = initialState,
  action: SetAgencyPublicUser
) => {
  switch (action.type) {
    case SET_AGENCY_PUBLIC_USER: {
      return { ...action.payload };
    }

    default: {
      return state;
    }
  }
};
