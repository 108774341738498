import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchAgencyData,
  fetchAgencyFolders,
  getAgencyFilesList,
  keepFilesAndFoldersBreadcrumbsAction,
  resetFilesAndFoldersBreadcrumbsAction,
  setActiveFolder,
} from "../../store/actions/agencyFile";
import { AgencyFileDropState, PublicAgencyLinkState } from "../../store/types";
import {
  validateAgencyLink,
  validateAgencyLinkPassword,
} from "../../store/actions/agencyPublicLink";

import { Layout, Menu, MenuItem, Dropdown } from "pyxis-ui-kit";
import InfiniteScroll from "react-infinite-scroll-component";

import { getUrlProperties } from "../../utilities/common-function";
import {
  buildParentHierarchyJson,
  formatDate,
  getDataFromParams,
  removeFolderParameter,
} from "../AgencyFileDrop/utils";

import AgencyFileView from "../AgencyFileView";
import PromptPopUp from "../../components/shared/PromptPopUp";
import { FullScreenSpinner } from "../../components/shared/Spinner";
import UploadFilesAndFolderForAgency from "./components/UploadFilesAndFolder";
import AgencyFolderItem from "../AgencyFileDrop/components/AgencyFolderItem";
import AgencyFoldersBreadcrumbs from "./components/AgencyFoldersBreadcrumbs";
import { useHistory } from "react-router-dom";

const PublicAgencyView = () => {
  const dispatch = useDispatch();
  const state = useSelector(
    (state: {
      agencyPublicLink: PublicAgencyLinkState;
      agencyFileDrop: AgencyFileDropState;
    }) => ({
      ...state.agencyPublicLink,
      ...state.agencyFileDrop,
    })
  );

  const {
    agency_id,
    currentAgencyFolders,
    loading,
    newAgencySelected,
    public_access_token,
    passwordRequired,
    filesDataBreadcrumbs,
    folderDataBreadcrumbs,
  } = state;

  const history = useHistory();

  const [activeDropdown, setActiveDropdown] = useState<boolean>(false);
  const { baseUrl, search } = getUrlProperties();
  const [showPrompt, setShowPrompt] = useState<boolean>(passwordRequired);
  const linkId = getDataFromParams(search, "shareable_link_key");
  const folders = getDataFromParams(search, "folder");
  const foldersList = folders?.split("-");
  const foldersBreadCrumbs = buildParentHierarchyJson(
    folderDataBreadcrumbs,
    foldersList
  );
  const filteredFolderDataBreadcrumbs = folderDataBreadcrumbs.filter(
    (item) => !!item.folder && item.folder !== "0"
  );

  const filteredFilesDataBreadcrumbs = filesDataBreadcrumbs.filter(
    (item) => !!item.folder && item.folder !== "0"
  );

  const [isURLContainsFolders, setIsURLContainsFolders] = useState<boolean>(
    !!folders
  );

  const [hasMoreFolders, setHasMoreFolders] = useState<boolean>(
    currentAgencyFolders.results.length < currentAgencyFolders.count
  );

  useEffect(() => {
    setHasMoreFolders(
      currentAgencyFolders.results.length < currentAgencyFolders.count
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAgencyFolders]);

  useEffect(() => {
    setShowPrompt(passwordRequired);
  }, [passwordRequired]);

  useEffect(() => {
    if (!public_access_token) {
      dispatch(validateAgencyLink({ linkId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isHavingFolders = getDataFromParams(search, "folder");
    setIsURLContainsFolders(!!isHavingFolders);
  }, [folders, search, history]);

  useEffect(() => {
    // if (!!agency_id && !isURLContainsFolders) {
    //   dispatch(fetchAgencyFolders({ agency_id }));
    //   dispatch(fetchAgencyData(agency_id));
    // }
    if (!!isURLContainsFolders && !!public_access_token) {
      const foldersList = folders.split("-");
      // const folders = getDataFromParams(search, "folder")
      for (let i = 0; i < foldersList?.length; i++) {
        const folder = foldersList[i];
        dispatch(fetchAgencyFolders({ agency_id, parent: folder }));

        dispatch(
          getAgencyFilesList({
            agency_id,
            folders: folder,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency_id]);

  useEffect(() => {
    if (!!agency_id && !isURLContainsFolders) {
      dispatch(fetchAgencyFolders({ agency_id }));
      dispatch(fetchAgencyData(agency_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency_id, isURLContainsFolders]);

  const handlePasswordSubmit = useCallback(
    (value: string) => {
      dispatch(validateAgencyLinkPassword({ linkId, password: value }));
      setShowPrompt(false);
    },
    [dispatch, linkId]
  );

  const fetchMoreFolders = useCallback(() => {
    if (currentAgencyFolders.current_page >= currentAgencyFolders.pages) {
      setHasMoreFolders(false);
      return;
    }

    const requiredData = {
      agency_id,
      page: currentAgencyFolders.current_page + 1,
    };
    dispatch(fetchAgencyFolders(requiredData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAgencyFolders, dispatch]);

  const handleFolderClick = useCallback(
    (folder) => {
      let newurl =
        baseUrl +
        (isURLContainsFolders
          ? `?${removeFolderParameter(search)}&folder=${folder.id}`
          : `?${search}&folder=${folder.id}`);
          history.push(
            isURLContainsFolders
              ? `?${removeFolderParameter(search)}&folder=${folder.id}`
              : `?${search}&folder=${folder.id}`
          );
      // window.history.pushState({ path: newurl }, "", newurl);
      dispatch(setActiveFolder({ folderId: folder.id }));
      dispatch(getAgencyFilesList({ agency_id, folders: folder.id }));
      dispatch(fetchAgencyFolders({ agency_id, parent: folder.id }));
      setIsURLContainsFolders(true);
    },
    [agency_id, baseUrl, dispatch, history, isURLContainsFolders, search]
  );

  const handleAgencyFileDropClick = useCallback(() => {
    if (!folders) {
      return;
    }
    const newurl = baseUrl + "?" + search.replace(/&?folder=[^&]*/, "");
    window.history.pushState({ path: newurl }, "", newurl);
    setIsURLContainsFolders(false);
    dispatch(resetFilesAndFoldersBreadcrumbsAction());
    dispatch(fetchAgencyFolders({ agency_id }));
  }, [agency_id, baseUrl, dispatch, folders, search]);

  const handleFolderBreadcrumbClick = useCallback(
    (folderId) => {
      let newurl = baseUrl + "?" + search.replace(/&?folder=[^&]*/, "");
      const index = foldersList.indexOf(String(folderId)); // Find the index of the folderId
      const updatedFolderIds =
        index !== -1 ? foldersList.slice(0, index + 1) : [];
      const foldersUrl = updatedFolderIds.join("-");
      newurl = newurl + `&folder=${foldersUrl}`;
      window.history.pushState({ path: newurl }, "", newurl);
      dispatch(
        keepFilesAndFoldersBreadcrumbsAction({ folderIds: updatedFolderIds })
      );
      setActiveDropdown(false);
    },
    [baseUrl, dispatch, foldersList, search]
  );

  const breadcrumbsMenu = () => {
    return (
      <Menu style={{ borderRadius: "1rem" }}>
        {foldersBreadCrumbs
          .slice(2, foldersBreadCrumbs.length - 1)
          .map((folder) => (
            <MenuItem
              key={folder.id}
              onClick={() => handleFolderBreadcrumbClick(folder.id)}
            >
              {folder.name}
            </MenuItem>
          ))}
      </Menu>
    );
  };

  return (
    <>
      {loading && <FullScreenSpinner />}
      {showPrompt && !public_access_token ? (
        <PromptPopUp
          isUpdate={false}
          isVleRequired={false}
          handleClose={() => setShowPrompt(true)}
          onSubmit={(
            value: { value: string; vle_code: string },
            setLoader: Function
          ) => handlePasswordSubmit(value.value)}
          title={"Enter the required details"}
          text={"Please enter the password to open this link"}
          placeholder={"Enter Password"}
          submitBtnText={"Get access"}
          inputText={""}
        />
      ) : (
        <div
          style={{
            margin: foldersList?.length ? "0rem" : "0rem 2rem",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <Layout>
            <div
              className="agency-files-header"
              style={{
                height: "8.4rem",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <AgencyFoldersBreadcrumbs
                setIsURLContainsFolders={setIsURLContainsFolders}
                agency_id={agency_id}
              />

              {(filteredFolderDataBreadcrumbs[0]?.data?.results?.length ||
                filteredFilesDataBreadcrumbs[0]?.data?.results?.length) &&
              foldersList?.length === 1 ? (
                <UploadFilesAndFolderForAgency />
              ) : (
                <></>
              )}
            </div>
          </Layout>

          {!!isURLContainsFolders ? (
            <AgencyFileView agency_id={agency_id} />
          ) : (
            <>
              <div className="agency-header">
                <div style={{ margin: "0 1rem" }}>
                  <div className="agency-header__content">
                    <div className="agency-header__info">
                      <div className="agency-header__name">
                        HDFC shared this folder with you
                      </div>
                      <div className="agency-header__data">
                        Created on:&nbsp;
                        {newAgencySelected
                          ? formatDate(newAgencySelected.created_at)
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="folders_wrapper"
                id="folders_wrapper"
                style={{ height: "58rem", overflow: "scroll" }}
              >
                <InfiniteScroll
                  dataLength={currentAgencyFolders.results.length}
                  next={fetchMoreFolders}
                  hasMore={hasMoreFolders}
                  loader={""}
                  scrollableTarget="folders_wrapper"
                >
                  <div className="folders-content">
                    {currentAgencyFolders.results.map((folder, index) => (
                      <AgencyFolderItem
                        folder={folder}
                        index={index}
                        handleFolderClick={handleFolderClick}
                        isForPublic={true}
                      />
                    ))}
                  </div>
                </InfiniteScroll>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default PublicAgencyView;
