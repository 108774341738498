import React, { useCallback, useEffect, useState } from "react";
import FileIcon from "../../assets/img/VLEFile.png";
import { ReactComponent as IconRemove } from "../../assets/svg/vle-delete.svg";
import { ReactComponent as resetDeleteIcon } from "../../assets/svg/Icon-container.svg";
import { ReactComponent as RestoreStatusIcon } from "../../assets/svg/restore-log.svg";
import Icon from "@ant-design/icons";
import "../VLEStatus/VLEStatus.scss";
import { Button, Input } from "pyxis-ui-kit";
import { Tooltip } from "antd";
import { Table } from "antd";
import httpService from "../../HttpService/HttpService";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { VLEProductId } from "../VLEStatus/index";
import { useHistory } from "react-router-dom";
import { ConfirmationModal } from "./ConfirmationModal";
import debounce from "lodash/debounce";
import useDebounce from "./useDebounce";
import { DateFilter } from "./DateFilter";
import { toast } from "react-toastify";
import { openToast } from "../../Toasts";
const convertTableData = (data) => {
  return data.map((item, index) => {
    const options = { timeZone: "UTC", hour12: false };
    if (item.submit_proof_date != null) {
      var submit_proof_date = new Date(item.submit_proof_date)
        .toLocaleString()
        .replace(",", "");
    } else {
      submit_proof_date = "null";
    }
    return {
      key: index,
      vle_id: item.vle_id,
      id: item?.id,
      // expiration_date: new Date(item.expiration_date)
      //   .toLocaleString("en-GB", options)
      //   .replace(",", ""),
      isDownloaded: item?.isDownloaded ? "Yes" : "No",
      trashed_at: new Date(item?.trashed_at).toLocaleString().replace(",", ""),
      // expiration_date: item.expiration_date,
      submit_proof_flag: item.submit_proof_flag,
      submit_proof_date: submit_proof_date,
      outside_image_proof_url: String(item.outside_image_proof_url).replace(
        /"/g,
        ""
      ),
      inside_image_proof_url: String(item.inside_image_proof_url).replace(
        /"/g,
        ""
      ),
      project_link: String(item.project_link).replace(/"/g, ""),
      folder_name: String(item.folder_name).replace(/"/g, ""),
      url_hash: item.url_hash,
    };
  });
};

export default function VLEStatus() {
  const getTokenOrPublic = (state: any) =>
    state.authentication.token || state.publicLink.token;
  const state = useSelector((state: any) => state);
  const [tableData, setTableData] = React.useState([]);
  const [filteredTableData, setFilteredTableData] = React.useState([]);
  const [isRejectReponseModalVisible, setRejectModalVisible] = useState(false);
  const [isDeleteBatchModalVisible, setDeleteBatchModalVisible] = useState(
    false
  );
  const [showDeleteHighlight, setShowDeleteHighlight] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [deletevleId, setDeletevleId] = useState("");
  const [date, setDate] = useState("");
  const selectedProductId = useSelector(
    (state: { roles: any }) => state.roles.selectedProductId
  );
  const history = useHistory();
  const [selectedRowsKeys, setSelectedRowsKeys] = useState([]);
  const rowSelection = {
    onChange: (_, selectedRows: any) => {
      setSelectedRowsKeys(selectedRows.map((row) => row.key));
    },
    selectedRowKeys: selectedRowsKeys,
  };
  const isRestoreLog = history.location.pathname.includes("vle-logs");
  const restoreDataFromApis = async (apiUrl, data) => {
    const responses = await fetch(apiUrl, {
      method: "PUT",
      headers: { token: getTokenOrPublic(state) },
      body: data,
    }).then((response) => {
      setDeleteBatchModalVisible(false);
      // toast.success("Successfully Restored VLE data", {
      //   position: "bottom-center",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   //@ts-ignore
      //   icon: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   //@ts-ignore
      //   theme: "colored",
      // });
      openToast("success", "Successfully Restored VLE data");
    });
    return;
  };
  const onBatchRestore = useCallback(
    (selectedRowsKeys) => {
      const body = {
        data: tableData
          .filter((data) => selectedRowsKeys.includes(data.vle_id))
          .map((item) => item?.id),
      };
      restoreDataFromApis(
        `${process.env.REACT_APP_DESIGN_URI}/api/getLogsHistory/?url_hash=${params.url_hash}`,
        JSON.stringify(body)
      );
      setTableData((p) =>
        p.filter((item) => !selectedRowsKeys.includes(item.vle_id))
      );
      setSelectedRowsKeys((p) =>
        p.filter((id) => !selectedRowsKeys.includes(id))
      );
      setDeletevleId("");
    },
    [tableData, setDeleteBatchModalVisible]
  );
  const columns = [
    {
      title: "IDs",
      dataIndex: "vle_id",
      key: "vle_id",
    },
    {
      title: "Url hash",
      dataIndex: "url_hash",
      key: "url_hash",
    },
    {
      title: "Project link",
      dataIndex: "project_link",
      key: "project_link",
      ellipsis: true,
    },
    {
      title: isRestoreLog ? "DeletedOn" : "Download Status",
      dataIndex: isRestoreLog ? "trashed_at" : "isDownloaded",
      key: isRestoreLog ? "trashed_at" : "isDownloaded",
    },
    {
      title: "Folder name",
      dataIndex: "folder_name",
      key: "folder_name",
    },
    {
      title: "Inside image proof",
      dataIndex: "inside_image_proof_url",
      key: "inside_image_proof_url",
      ellipsis: true,
    },
    {
      title: "Outside image proof",
      dataIndex: "outside_image_proof_url",
      key: "outside_image_proof_url",
      ellipsis: true,
    },
    {
      title: "Submit proof date",
      dataIndex: "submit_proof_date",
      key: "submit_proof_date",
    },
    {
      title: isRestoreLog ? "Restore" : "Actions",
      dataIndex: "delete",
      key: "delete",
      render: (_, record) => {
        return isRestoreLog ? (
          <Tooltip
            placement='bottomRight'
            title={"Restore"}
            key={"Restore"}
            getPopupContainer={(triggerNode) => triggerNode}
          >
            <div
              className={`restore1 ${
                showDeleteHighlight === record.vle_id
                  ? "restore1-highlight1"
                  : "restore1-highlight2"
              }`}
            >
              <RestoreStatusIcon
                className='approval-icon'
                onMouseEnter={() => setShowDeleteHighlight(record.vle_id)}
                onMouseLeave={() => setShowDeleteHighlight(false)}
                onClick={() => {
                  const body = {
                    data: [record.id],
                  };
                  restoreDataFromApis(
                    `${process.env.REACT_APP_DESIGN_URI}/api/getLogsHistory/?url_hash=${params.url_hash}`,
                    JSON.stringify(body)
                  );
                  setDeletevleId(record.vle_id);
                  setTableData((p) =>
                    p.filter((item) => item.vle_id !== record.vle_id)
                  );
                }}
              />
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            getPopupContainer={(triggerNode) => triggerNode}
            placement='bottomRight'
            title={
              "Delete available data from the data base and re enable submission option the VLE"
            }
            key={
              "Delete available data from the data base and re enable submission option the VLE"
            }
            style={{ position: "absolute" }}
          >
            <div
              className={`delete1 ${
                showDeleteHighlight === record.vle_id
                  ? "delete1-highlight1"
                  : "delete1-highlight2"
              }`}
            >
              <IconRemove
                className='approval-icon'
                onMouseEnter={() => setShowDeleteHighlight(record.vle_id)}
                onMouseLeave={() => setShowDeleteHighlight(false)}
                onClick={() => {
                  setRejectModalVisible(true);
                  setDeletevleId(record.vle_id);
                }}
              />
            </div>
          </Tooltip>
        );
      },
    },
  ];
  const deleteDataFromApis = async (apiUrl, data, setRejectModalVisible) => {
    const responses = await fetch(apiUrl, {
      method: "DELETE",
      headers: { token: getTokenOrPublic(state) },
      body: data,
    }).then((response) => {
      setRejectModalVisible(false);
    });
    return;
  };
  const params: any = useParams();
  const updateReponsesBulk = useCallback(
    (vleId) => {
      const body = {
        vle_ids: [vleId],
        url_hash: params.url_hash,
      };
      deleteDataFromApis(
        `${process.env.REACT_APP_DESIGN_URI}/api/project/deleteVledata/`,
        JSON.stringify(body),
        setRejectModalVisible
      );
      setTableData((p) => p.filter((item) => item.vle_id !== vleId));

      setDeletevleId("");
    },
    [setTableData, setRejectModalVisible]
  );
  const onBatchDelete = useCallback(
    (selectedRowsKeys) => {
      const body = {
        vle_ids: selectedRowsKeys,
        url_hash: params.url_hash,
      };
      deleteDataFromApis(
        `${process.env.REACT_APP_DESIGN_URI}/api/project/deleteVledata/`,
        JSON.stringify(body),
        setDeleteBatchModalVisible
      );
      setTableData((p) =>
        p.filter((item) => !selectedRowsKeys.includes(item.vle_id))
      );
      setSelectedRowsKeys((p) =>
        p.filter((id) => !selectedRowsKeys.includes(id))
      );
      setDeletevleId("");
    },
    [setTableData, setDeleteBatchModalVisible]
  );
  useEffect(() => {
    getFolderDetail(params.url_hash, isRestoreLog);
    if (selectedProductId != VLEProductId) {
      history.push("/");
    }
  }, [selectedProductId]);

  const getFolderDetail = (folderId, isRestoreLog) => {
    if (isRestoreLog) {
      httpService
        .get(
          process.env.REACT_APP_DESIGN_URI,
          `/api/getLogsHistory/?re_path_hash=${folderId}`,
          getTokenOrPublic(state)
        )
        .then((res) => {
          setTableData(res.data.data.result);
        });
    } else {
      httpService
        .get(
          process.env.REACT_APP_DESIGN_URI,
          `/api/vleDataBasedonFolder/?re_path_hash=${folderId}`,
          getTokenOrPublic(state)
        )
        .then((res) => {
          setTableData(res.data.data.result);
        });
    }
  };

  const handleBack = () => {
    window.history.back();
  };
  // const handleDelayedSearch = debounce(
  //   (text: string) =>
  //     setFilteredTableData(
  //       tableData.filter((item) => item?.vle_id?.includes(text))
  //     ),
  //   1000
  // );
  useDebounce(
    () => {
      setFilteredTableData(
        tableData.filter((item) => item?.vle_id?.includes(searchValue))
      );
    },
    [tableData, searchValue],
    700
  );
  function tableToCSV() {
    let selectedTableData = convertTableData(tableData);
    if (selectedRowsKeys.length) {
      selectedTableData = convertTableData(tableData)?.filter((data) =>
        selectedRowsKeys.includes(data?.vle_id)
      );
    }
    const _tableData = selectedTableData;
    _tableData.forEach((item, index) => {
      delete item.key;
      delete item.submit_proof_flag;
      delete item.trashed_at;
      delete item.expiration_date;
    });

    //@ts-ignore
    const header = Object.keys(_tableData[0]).join(",");
    const rows = _tableData.map((item) =>
      Object.values(item)
        .map((value) => (value !== null ? `"${value}"` : ""))
        .join(",")
    );
    const csvContent = [header, ...rows].join("\n");

    return csvContent;
  }
  function downloadCSVFile(csv_data, folderName) {
    let CSVFile;
    CSVFile = new Blob([csv_data], { type: "text/csv" });

    let temp_link = document.createElement("a");
    const fileName = `${folderName}.csv`;

    temp_link.download = fileName;
    let url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    temp_link.click();
    document.body.removeChild(temp_link);
    toast.success("Successfully downloaded VLE data", {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: false,
      //@ts-ignore
      icon: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      //@ts-ignore
      theme: "colored",
    });
  }

  const handleDownloadCSV = () => {
    downloadCSVFile(tableToCSV(), params.folder_name);
  };

  return (
    <div className='profile-wrapper'>
      <div className='VLE-status-container'>
        <div className='header' onClick={handleBack}>
          <span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                d='M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z'
                fill='#18236C'
              />
            </svg>
          </span>
          <span className='header-title'>
            {isRestoreLog ? "Logs" : params.folder_name}
          </span>
        </div>
        <div className='sub-header'>
          <div className='left-group'>
            <Input
              className='search'
              value={searchValue}
              placeholder='Search'
              allowClear
              suffix={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='14'
                  height='15'
                  viewBox='0 0 14 15'
                  fill='none'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M6.33333 1.5C3.66396 1.5 1.5 3.66396 1.5 6.33333C1.5 9.00271 3.66396 11.1667 6.33333 11.1667C9.00271 11.1667 11.1667 9.00271 11.1667 6.33333C11.1667 3.66396 9.00271 1.5 6.33333 1.5ZM0.5 6.33333C0.5 3.11167 3.11167 0.5 6.33333 0.5C9.55499 0.5 12.1667 3.11167 12.1667 6.33333C12.1667 9.55499 9.55499 12.1667 6.33333 12.1667C3.11167 12.1667 0.5 9.55499 0.5 6.33333Z'
                    fill='#7D899C'
                  />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M9.64645 9.95357C9.84171 9.75831 10.1583 9.75831 10.3536 9.95358L13.6869 13.2869C13.8822 13.4822 13.8821 13.7988 13.6869 13.9941C13.4916 14.1893 13.175 14.1893 12.9798 13.9941L9.64644 10.6607C9.45118 10.4654 9.45119 10.1488 9.64645 9.95357Z'
                    fill='#7D899C'
                  />
                </svg>
              }
              onChange={(e: any) => {
                setSearchValue(e.target.value);
              }}
              // onPressEnter={changeSearchFilter}
              // onBlur={changeSearchFilter}
              autoFocus
            />
            <DateFilter
              // className="ml-24 cursor-default"
              onChange={(dates) => {
                setDate(dates);
                if (!dates[0] && !dates[1]) {
                  setFilteredTableData(tableData);
                  return;
                } else {
                  setFilteredTableData(
                    tableData.filter((a: any) => {
                      const datePast = a[
                        isRestoreLog ? "trashed_at" : "submit_proof_date"
                      ]?.split("T")[0];
                      const dateSelected =
                        typeof dates[0] === "string"
                          ? dates[0]?.split("T")[0]
                          : null;
                      const dateSelected1 =
                        typeof dates[0] === "string"
                          ? dates[1]?.split("T")[0]
                          : null;
                      return (
                        //@ts-ignore
                        new Date(datePast) -
                          //@ts-ignore
                          new Date(dateSelected) >=
                          0 &&
                        //@ts-ignore
                        new Date(dateSelected1) -
                          //@ts-ignore
                          new Date(datePast) >=
                          0
                      );
                    })
                  );
                  return;
                }
              }}
              value={date}
              placeholder='Custom'
              // questionnaire={survey}
            />
          </div>
          <ConfirmationModal
            isVisible={isDeleteBatchModalVisible}
            handleClose={() =>
              setDeleteBatchModalVisible(!isDeleteBatchModalVisible)
            }
            title='Delete'
            textButton='Delete'
            onSuccess={() => onBatchDelete(selectedRowsKeys)}
          />
          <div className='right-group'>
            {selectedRowsKeys.length ? (
              <div className='batch-delete-button'>
                <span>
                  {selectedRowsKeys.length} Id(s) selected
                  <Icon
                    component={resetDeleteIcon}
                    className='reset-icon'
                    onClick={() => setSelectedRowsKeys([])}
                  />
                </span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='2'
                  height='22'
                  viewBox='0 0 2 22'
                  fill='none'
                >
                  <path
                    d='M1 1.5L0.999999 20.5'
                    stroke='#E0E3E5'
                    stroke-width='2'
                    stroke-linecap='round'
                  />
                </svg>
                <Tooltip
                  placement='bottomRight'
                  getPopupContainer={(triggerNode) => triggerNode}
                  title={
                    isRestoreLog
                      ? "Restore"
                      : "Delete available data from the data base and re enable submission option the VLE"
                  }
                  key={
                    isRestoreLog
                      ? "Restore"
                      : "Delete available data from the data base and re enable submission option the VLE"
                  }
                >
                  <div
                    className={`batch-action-icon ${
                      isRestoreLog
                        ? "batch-action-icon-restore3"
                        : "batch-action-icon-delete3"
                    }`}
                  >
                    {isRestoreLog ? (
                      <RestoreStatusIcon
                        className='approval-icon'
                        onClick={() =>
                          isRestoreLog
                            ? onBatchRestore(selectedRowsKeys)
                            : setDeleteBatchModalVisible(true)
                        }
                      />
                    ) : (
                      <IconRemove
                        className='approval-icon'
                        onClick={() =>
                          isRestoreLog
                            ? onBatchRestore(selectedRowsKeys)
                            : setDeleteBatchModalVisible(true)
                        }
                      />
                    )}
                  </div>
                </Tooltip>
              </div>
            ) : (
              <span />
            )}
            <Button className='download-csv-btn' onClick={handleDownloadCSV}>
              Download CSV
            </Button>
          </div>
        </div>
        <ConfirmationModal
          isVisible={isRejectReponseModalVisible}
          handleClose={() =>
            setRejectModalVisible(!isRejectReponseModalVisible)
          }
          title='Delete'
          textButton='Delete'
          onSuccess={() => updateReponsesBulk(deletevleId)}
        />
        <div className='table-div'>
          <Table
            className='table-custom'
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            dataSource={convertTableData(filteredTableData).map((row) => ({
              ...row,
              key: row.vle_id,
            }))}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
}
