import { call, put, takeEvery } from "redux-saga/effects";
import {
  VALIDATE_AGENCY_LINK,
  VALIDATE_AGENCY_LINK_PASSWORD,
  ValidateAgencyLink,
  ValidateAgencyLinkPassword,
} from "../types";
import { openToast } from "../../Toasts";
import { store } from "../store";
import httpService from "../../HttpService/HttpService";
import HttpService from '../../HttpService/HttpService';
import { DESIGN_URI } from "../../utilities/paths";
import { agenciesAPI } from "../../MappedAPI/MappedAPI";
import {
  setAgencyPublicUser,
  validateAgencyLinkPassword,
} from "../actions/agencyPublicLink";
import { stopLoadingOnErrorAction } from "../actions/agencyFile";

function* validateAgencyLinkSaga(action: ValidateAgencyLink) {
  let { linkId } = action.payload;
  try {
    let response = yield call(
      HttpService.get,
      DESIGN_URI,
      `${agenciesAPI}validate-share-link?shareable_link_key=${linkId}`,
      undefined
    );
    if (!response.data.error) {
      yield put(setAgencyPublicUser(response.data.data));
      yield put(stopLoadingOnErrorAction());
      if (!response.data.data.passwordRequired) {
        yield put(validateAgencyLinkPassword({ linkId }));
      }
    }
  } catch (error) {
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

function* validateAgencyLinkPasswordSaga(action: ValidateAgencyLinkPassword) {
  let { linkId, password } = action.payload;
  try {
    const params = `access-token?agency_hash=${linkId}${
      !!password ? `&password=${password}` : ""
    }`;
    let response = yield call(
      httpService.get,
      DESIGN_URI,
      `${agenciesAPI}${params}`,
      undefined
    );
    if (!response.data.error) {
      yield put(setAgencyPublicUser(response.data.data));
    }
  } catch (error) {
    openToast(
      "error",
      error?.response?.data?.message || "Something Went Wrong!"
    );
  }
}

export function* publicAgencyLinkWatcher() {
  yield takeEvery(VALIDATE_AGENCY_LINK, validateAgencyLinkSaga);
  yield takeEvery(
    VALIDATE_AGENCY_LINK_PASSWORD,
    validateAgencyLinkPasswordSaga
  );
}
