import React, { FC, useCallback, useState } from "react";
import { Button } from "pyxis-ui-kit";

import { useDispatch, useSelector } from "react-redux";

import { Agencies, AgencyFileDropState } from "../../../../store/types";
import { deleteAgency } from "../../../../store/actions/agencyFile";

import { formatDate } from "../../utils";
import { openToast } from "../../../../Toasts";
import { getUrlProperties } from "../../../../utilities/common-function";

import ShareAgencyModal from "../ShareAgecyModal";
import CreateResourceModal from "../CreateResourceModal";
import DeleteResourceModal from "../DeleteResourceModal";

import shareIcon from "../../../../assets/svg/shareIcon.svg";
import deleteIcon from "../../../../assets/svg/delete_agency.svg";
import "./index.scss";

interface AgencyHeaderProps {
  agency: Agencies;
  setAgency: Function;
  titleText: string;
  setTitleText: (title: string) => void;
  handleCreateFolderAction: Function;
  isCreateFolderModalOpen: boolean;
  setIsCreateFolderModalOpen: (isOpen: boolean) => void;
}

const AgencyHeader: FC<AgencyHeaderProps> = ({
  agency,
  setAgency,
  titleText,
  setTitleText,
  handleCreateFolderAction,
  isCreateFolderModalOpen,
  setIsCreateFolderModalOpen,
}) => {
  const dispatch = useDispatch();
  const workspace = useSelector(({ roles }: any) => roles.selectedProductId);
  const { agenciesList } = useSelector(
    (state: { agencyFileDrop: AgencyFileDropState }) => state.agencyFileDrop
  );
  const [isDeleteAgencyModalOpen, setIsDeleteAgencyModalOpen] = useState<
    boolean
  >(false);

  const [isShareAgencyModalOpen, setIsShareAgencyModalOpen] = useState<boolean>(
    false
  );
  const currentAgency = agenciesList.results.filter(
    (item) => item.id === agency.id
  );
  const handleCreateFolderModal = useCallback(() => {
    setIsCreateFolderModalOpen(true);
  }, [setIsCreateFolderModalOpen]);

  const handleDeleteAgencyModal = useCallback(() => {
    setIsDeleteAgencyModalOpen(true);
  }, []);

  const handleShareAgencyModal = useCallback(() => {
    if (!currentAgency[0].folders_count) {
      openToast("error", "Please add some folders");
    } else {
      setIsShareAgencyModalOpen(true);
    }
  }, [currentAgency]);

  const handleAgencyDelete = useCallback(() => {
    const { baseUrl } = getUrlProperties();
    dispatch(deleteAgency({ id: agency.id, workspace }));
    setIsDeleteAgencyModalOpen(false);
    setAgency(null);
    // To navigate to the base URL:
    window.location.href = baseUrl;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, agency]);
  return (
    <>
      <div className="agency-header">
        <div className="agency-header__content">
          <div className="agency-header__info">
            <div className="agency-header__name">
              {agency ? agency.agency_name : ""}
            </div>
            <div className="agency-header__data">
              {currentAgency[0]
                ? currentAgency[0].folders_count > 0
                  ? currentAgency[0].folders_count
                  : 0
                : 0}{" "}
              Folders &nbsp;&nbsp;·&nbsp;&nbsp;&nbsp; Created on:{" "}
              {formatDate(agency.created_at)}
            </div>
          </div>
          <div className="agency-header__actions">
            <Button
              btnsize="sm"
              className="agency-header__button"
              onClick={handleCreateFolderModal}
            >
              <span className="agency-header__button-text">
                +&nbsp;&nbsp;&nbsp;Create new folder
              </span>
            </Button>
            <Button
              btnsize="sm"
              className="agency-header__button"
              onClick={handleDeleteAgencyModal}
            >
              <span className="agency-header__button-text">
                <img src={deleteIcon} alt="delete agency" />
                &nbsp;&nbsp;&nbsp;Delete Agency File Drop
              </span>
            </Button>
            <Button
              btnsize="sm"
              className="agency-header__button agency-header__button--share"
              onClick={handleShareAgencyModal}
            >
              <span className="agency-header__button-text agency-header__button--share_text">
                <img src={shareIcon} alt="share agency" />
                &nbsp;&nbsp;Share
              </span>
            </Button>
          </div>
        </div>
      </div>
      <CreateResourceModal
        isModalOpen={isCreateFolderModalOpen}
        setModalOpen={setIsCreateFolderModalOpen}
        modalTitle={"New folder"}
        modalDesc={"Name your folder"}
        text={titleText}
        setText={setTitleText}
        handleCreateButton={handleCreateFolderAction}
      />
      <DeleteResourceModal
        isModalOpen={isDeleteAgencyModalOpen}
        setModalOpen={setIsDeleteAgencyModalOpen}
        modalTitle={"Delete Agency?"}
        modalDesc={"Are you sure you want to delete this Agency File Drop."}
        handleDeleteClick={handleAgencyDelete}
      />
      <ShareAgencyModal
        isModalOpen={isShareAgencyModalOpen}
        setModalOpen={setIsShareAgencyModalOpen}
        agency={agency}
      />
    </>
  );
};

export default AgencyHeader;
