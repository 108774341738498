import { getDataFromParams } from "../../Pages/AgencyFileDrop/utils";
import { openToast } from "../../Toasts";
import { getUrlProperties } from "../../utilities/common-function";
import {
  ADD_NEW_AGENCY,
  AddNewAgency,
  AgencyFileDropState,
  CREATE_NEW_AGENCY,
  CreateNewAgency,
  DELETE_AGENCY,
  FETCH_AGENCY_LIST,
  DeleteAgency,
  FetchAgencyList,
  GET_AGENCY_LIST,
  GetAgencyList,
  FetchAgencyFolders,
  FETCH_AGENCY_FOLDERS,
  SET_AGENCY_FOLDERS,
  SetAgencyFolders,
  CreateNewFolder,
  CREATE_NEW_FOLDER,
  ADD_NEW_AGENCY_FOLDER,
  AddNewAgencyFolder,
  DELETE_AGENCY_FOLDER,
  DeleteAgencyFolder,
  RenameAgencyFolder,
  RENAME_AGENCY_FOLDER,
  FetchAgencyData,
  FETCH_AGENCY_DATA,
  SetAgencyData,
  SET_AGENCY_DATA,
  GetAgencyShareableKey,
  GET_AGENCY_SHAREABLE_KEY,
  SetAgencyShareableKey,
  SET_AGENCY_SHAREABLE_KEY,
  GetAgencyFilesList,
  POST_AGENCY_SHAREABLE_KEY,
  PostAgencyShareableKey,
  RESET_AGENCY_FILE_DROP,
  ResetAgencyFileDropAction,
  STOP_LOADING,
  StopLoadingOnErrorAction,
  SetActiveFolderId,
  SET_ACTIVE_FOLDER_ID,
  SET_CURRENT_AGENCY_FOLDERS_DATA_BREADCRUMBS,
  SetCurrentAgencyFolderDataBreadcrumbsAction,
  GET_AGENCY_FILES_LIST,
  SET_UPLOAD_FILE_IN_AGENCY,
  UPLOAD_FILE_IN_AGENCY,
  UploadFileInAgencyAction,
  SetUploadFileInAgencyAction,
  ResetFilesAndFoldersBreadcrumbsAction,
  RESET_FILES_AND_FOLDERS_BREADCRUMBS,
  KeepFilesAndFoldersBreadcrumbsAction,
  KEEP_FILES_AND_FOLDERS_BREADCRUMBS,
  DELETE_FOLDER_FILES,
  DeleteFolderFiles,
  REMOVE_DELETED_FOLDER_FROM_PARENT,
  RemoveDeletedFolderFromParent,
  REMOVE_DELETED_FOLDER_FROM_AGENCY,
  RemoveDeletedFolderFromAgency,
  FETCH_DAM_FOLDERS,
  FetchDAMFoldersAction,
  SET_DAM_FOLDERS,
  SetDAMFoldersAction,
  RESET_DAM_FOLDERS_BREADCRUMBS,
  ResetDamFoldersBreadcrumbs,
  REMOVE_FOLDERS_FROM_BREADCRUMBS_WITH_INDEX,
  RemoveFoldersFromBreadcrumbsWithIndex,
  ADD_NEW_FOLDER_FOR_COPY,
  AddNewFolderForCopy,
  RENAME_THE_NEWLY_ADDED_FOLDER_FOR_COPY,
  RenameTheNewlyAddedFolderForCopy,
  AddNewFolderIntoDamBreadcrumbs,
  ADD_NEW_FOLDER_INTO_DAM_BREADCRUMBS,
  COPY_FILES_AND_FOLDERS_TO_DAM_ACTION,
  CopyFileAndFoldersToDamAction,
} from "../types";

const initialState: AgencyFileDropState = {
  loading: true,
  isFetchingFolders: false,
  agenciesList: {
    pages: 1,
    page_size: 10,
    current_page: 1,
    count: 0,
    results: [],
  },
  currentAgencyFolders: {
    count: 0,
    current_page: 1,
    pages: 1,
    page_size: 10,
    parent: {},
    results: [],
  },
  defaultSelectedAgency: null,
  foldersOfDam: [],
  damFoldersBreadcrumbs: [],
  agencyToBeShared: {
    shareable_link_key: "",
    password: null,
    expiration_date: null,
    password_flag: false,
  },
  newAgencySelected: null,
  activeFolderId: null,
  currentAgencyFolderData: {
    pages: 1,
    page_size: 10,
    current_page: 1,
    count: 0,
    results: [],
  },
  filesDataBreadcrumbs: [
    {
      data: {
        pages: 1,
        page_size: 10,
        current_page: 1,
        count: 0,
        results: [],
      },
      folder: "0",
    },
  ],
  folderDataBreadcrumbs: [
    {
      data: {
        pages: 1,
        page_size: 10,
        current_page: 1,
        count: 0,
        parent: {},
        results: [],
      },
      folder: "0",
    },
  ],
};

export const agencyFileDropReducer = (
  state: AgencyFileDropState = initialState,
  action:
    | FetchAgencyList
    | GetAgencyList
    | CreateNewAgency
    | AddNewAgency
    | DeleteAgency
    | FetchAgencyFolders
    | SetAgencyFolders
    | CreateNewFolder
    | AddNewAgencyFolder
    | DeleteAgencyFolder
    | RenameAgencyFolder
    | FetchAgencyData
    | SetAgencyData
    | GetAgencyShareableKey
    | SetAgencyShareableKey
    | GetAgencyFilesList
    | PostAgencyShareableKey
    | ResetAgencyFileDropAction
    | StopLoadingOnErrorAction
    | SetActiveFolderId
    | SetCurrentAgencyFolderDataBreadcrumbsAction
    | UploadFileInAgencyAction
    | SetUploadFileInAgencyAction
    | ResetFilesAndFoldersBreadcrumbsAction
    | KeepFilesAndFoldersBreadcrumbsAction
    | DeleteFolderFiles
    | RemoveDeletedFolderFromParent
    | RemoveDeletedFolderFromAgency
    | FetchDAMFoldersAction
    | SetDAMFoldersAction
    | ResetDamFoldersBreadcrumbs
    | RemoveFoldersFromBreadcrumbsWithIndex
    | AddNewFolderForCopy
    | RenameTheNewlyAddedFolderForCopy
    | AddNewFolderIntoDamBreadcrumbs
    | CopyFileAndFoldersToDamAction
) => {
  switch (action.type) {
    case CREATE_NEW_AGENCY:
    case CREATE_NEW_FOLDER:
    case FETCH_AGENCY_DATA:
    case GET_AGENCY_SHAREABLE_KEY:
    case POST_AGENCY_SHAREABLE_KEY:
    case GET_AGENCY_FILES_LIST:
    case UPLOAD_FILE_IN_AGENCY:
    case FETCH_DAM_FOLDERS:
    case COPY_FILES_AND_FOLDERS_TO_DAM_ACTION: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_AGENCY_LIST: {
      const { page } = action.payload;
      return { ...state, loading: !!page ? false : true };
    }
    case SET_ACTIVE_FOLDER_ID: {
      return { ...state, activeFolderId: action.payload.folderId };
    }
    case STOP_LOADING: {
      return { ...state, loading: false };
    }
    case RESET_AGENCY_FILE_DROP: {
      return {
        loading: true,
        isFetchingFolders: false,
        agenciesList: {
          pages: 1,
          page_size: 10,
          current_page: 1,
          count: 0,
          results: [],
        },
        currentAgencyFolders: {
          count: 0,
          current_page: 1,
          pages: 1,
          page_size: 10,
          parent: {},
          results: [],
        },
        defaultSelectedAgency: null,
        agencyToBeShared: {
          shareable_link_key: "",
          password: null,
          expiration_date: null,
          password_flag: false,
        },
        newAgencySelected: null,
        filesDataBreadcrumbs: [
          {
            data: {
              pages: 1,
              page_size: 10,
              current_page: 1,
              count: 0,
              results: [],
            },
            folder: "0",
          },
        ],
        folderDataBreadcrumbs: [
          {
            data: {
              pages: 1,
              page_size: 10,
              current_page: 1,
              count: 0,
              parent: {},
              results: [],
            },
            folder: "0",
          },
        ],
      };
    }
    case FETCH_AGENCY_FOLDERS: {
      const { isAgencyChanged } = action.payload;
      return {
        ...state,
        isFetchingFolders: true,
        loading: !!isAgencyChanged ? true : state.loading,
      };
    }
    case GET_AGENCY_LIST: {
      const { data, shouldLoading, page } = action.payload;
      const allAgencies = [...state.agenciesList.results, ...data.results];
      return {
        ...state,
        defaultSelectedAgency:allAgencies[0] || null,
        agenciesList: {
          ...data,
          results: !!page
            ? [...state.agenciesList.results, ...data.results]
            : [...data.results],
        },
        loading: !!shouldLoading ? true : false,
      };
    }
    case ADD_NEW_AGENCY: {
      return {
        ...state,
        agenciesList: {
          ...state.agenciesList,
          results: [action.payload, ...state.agenciesList.results],
        },
        defaultSelectedAgency: action.payload,
        loading: false,
      };
    }

    case SET_AGENCY_DATA: {
      const { search } = getUrlProperties();
      const selectedAgencyId = getDataFromParams(search, "agency");
      const requiredAgency = [
        action.payload,
        ...state.agenciesList.results,
      ].filter((agency) => String(agency.id) === String(selectedAgencyId));
      return {
        ...state,
        defaultSelectedAgency: !!requiredAgency.length
          ? requiredAgency[0]
          : null,
        agenciesList: {
          ...state.agenciesList,
          results: [action.payload, ...state.agenciesList.results],
        },
        newAgencySelected: action.payload,
        loading: !!selectedAgencyId ? true : false,
      };
    }

    case DELETE_AGENCY: {
      const { id } = action.payload;
      return {
        ...state,
        agenciesList: {
          ...state.agenciesList,
          results: state.agenciesList.results.filter(
            (agency) => agency.id !== id
          ),
        },
        currentAgencyFolders: {
          ...state.currentAgencyFolders,
          results: [],
        },
        loading: true,
      };
    }

    case SET_AGENCY_SHAREABLE_KEY: {
      return {
        ...state,
        agencyToBeShared: {
          ...action.payload,
        },
        loading: false,
      };
    }
    case SET_AGENCY_FOLDERS: {
      const { data, page, parent, folderToBeRemoved } = action.payload;
      const folderExists = state.folderDataBreadcrumbs.some(
        (breadcrumb) => String(breadcrumb.folder) === String(parent)
      );

      return {
        ...state,
        currentAgencyFolders: {
          ...data,
          results: !!page
            ? [...state.currentAgencyFolders.results, ...data.results]
            : folderToBeRemoved
            ? [...data.results].filter(
                (folder) => String(folder.id) !== folderToBeRemoved
              )
            : [...data.results],
        },

        folderDataBreadcrumbs: folderExists
          ? !!page // if the page is there then it will find the existing folder and add the new files into that existing folders
            ? state.folderDataBreadcrumbs.map((breadcrumb) =>
                String(breadcrumb.folder) === String(parent)
                  ? {
                      ...breadcrumb,
                      data: {
                        ...breadcrumb.data,
                        ...data,
                        results: [...breadcrumb.data.results, ...data.results],
                      },
                    }
                  : breadcrumb
              )
            : state.folderDataBreadcrumbs
          : [
              ...state.folderDataBreadcrumbs,
              { data: action.payload.data, folder: parent },
            ],
        isFetchingFolders: false,
        loading: false,
      };
    }

    case ADD_NEW_AGENCY_FOLDER: {
      const { agency_id } = action.payload;

      // Increment the folders_count if the agency is found
      state.agenciesList.results = state.agenciesList.results.map((item) =>
        String(item.id) === agency_id
          ? { ...item, folders_count: (item.folders_count ?? 0) + 1 }
          : { ...item }
      );
      // increment in the folders_count is done to increase the count value in the agencies list or else
      // we have to make a call every time to get the data of agency
      return {
        ...state,
        agenciesList: {
          ...state.agenciesList,
          results: state.agenciesList.results,
        },
        currentAgencyFolders: {
          ...state.currentAgencyFolders,
          results: [action.payload, ...state.currentAgencyFolders.results],
        },
        loading: false,
      };
    }

    case DELETE_AGENCY_FOLDER: {
      const { id, agencyId } = action.payload;
      const { search } = getUrlProperties();
      const folder = getDataFromParams(search, "folder");
      const foldersList = folder?.split("-");
      let currentFolderId;
      if (!!foldersList?.length) {
        currentFolderId = foldersList[foldersList?.length - 1];
      }
      let requiredFolders: any;
      let folderToBeDeleted: any;

      folderToBeDeleted = state.currentAgencyFolders.results.find(
        (item) => String(item.id) === String(id)
      );
      // Decrement the folders_count if the agency is found
      state.agenciesList.results = state.agenciesList.results.map((item) =>
        String(item.id) === agencyId
          ? { ...item, folders_count: item.folders_count - 1 }
          : { ...item }
      );
      if (!!foldersList?.length && String(currentFolderId) !== id) {
        const filteredData = state.folderDataBreadcrumbs.find(
          (item) => String(item.folder) === String(currentFolderId)
        );
        folderToBeDeleted = filteredData?.data?.results.find(
          (item) => String(item.id) === String(id)
        );
        requiredFolders = filteredData?.data?.results?.filter(
          (folder) => String(folder.id) !== String(id)
        );
      }

      openToast("success", `${folderToBeDeleted.name} successfully deleted .`);

      return {
        ...state,
        agenciesList: {
          ...state.agenciesList,
          results: state.agenciesList.results,
        },
        currentAgencyFolders: {
          ...state.currentAgencyFolders,
          results: state.currentAgencyFolders.results.filter(
            (folder) => String(folder.id) !== id
          ),
        },

        folderDataBreadcrumbs:
          String(currentFolderId) !== id
            ? state.folderDataBreadcrumbs.map((item) =>
                String(item.folder) === String(currentFolderId)
                  ? {
                      ...item,
                      data: { ...item.data, results: requiredFolders },
                    }
                  : item
              )
            : state.folderDataBreadcrumbs,
        loading: false,
      };
    }
    case RENAME_AGENCY_FOLDER: {
      const { id, name } = action.payload;
      return {
        ...state,
        currentAgencyFolders: {
          ...state.currentAgencyFolders,
          results: state.currentAgencyFolders.results.map((item) =>
            String(item.id) === id ? { ...item, name } : { ...item }
          ),
        },
      };
    }
    case SET_CURRENT_AGENCY_FOLDERS_DATA_BREADCRUMBS: {
      const { data, folder, page } = action.payload;
      // Check if the folder already exists in the breadcrumbs
      const filesExists = state.filesDataBreadcrumbs.find(
        (breadcrumb) => String(breadcrumb.folder) === String(folder)
      );

      return {
        ...state,
        filesDataBreadcrumbs: filesExists
          ? !!page // if the page is there then it will find the existing folder and add the new files into that existing folders
            ? state.filesDataBreadcrumbs.map((breadcrumb) =>
                String(breadcrumb.folder) === String(folder)
                  ? {
                      ...breadcrumb,
                      data: {
                        ...breadcrumb.data,
                        ...data,
                        results: [...breadcrumb.data.results, ...data.results],
                      },
                    }
                  : breadcrumb
              )
            : state.filesDataBreadcrumbs
          : [...state.filesDataBreadcrumbs, action.payload],
        loading: false,
      };
    }

    case SET_UPLOAD_FILE_IN_AGENCY: {
      const { data, isFolder, folders } = action.payload;
      // Remove unwanted properties from folders
      const {
        hashedStructureFolderIds,
        totalCount,
        ...cleanedFolders
      } = folders[0];

      const { search } = getUrlProperties();
      const folder = getDataFromParams(search, "folder");
      const foldersList = folder.split("-");

      // Check if the folder already exists in the breadcrumbs
      const existingFile = state.filesDataBreadcrumbs.find(
        (breadcrumb) =>
          String(breadcrumb.folder) === foldersList[foldersList.length - 1]
      );

      const existingFolder = state.folderDataBreadcrumbs.find(
        (breadcrumb) =>
          String(breadcrumb.folder) === foldersList[foldersList.length - 1]
      );

      const addedFileInTheFolder = {
        ...existingFile,
        data: {
          ...existingFile.data,
          results: [
            { ...data, can_manage: true },
            ...existingFile.data.results,
          ],
        },
      };

      const newlyAddedFolder = {
        ...existingFolder,
        data: {
          ...existingFolder.data,
          results: [
            cleanedFolders, // Add the new folder first
            ...existingFolder.data.results.filter(
              (result) => result.id !== cleanedFolders.id
            ),
          ],
        },
      };

      if (
        existingFolder.data.results.length <
          newlyAddedFolder.data.results.length &&
        isFolder
      ) {
        openToast("success", "Folder uploaded successfully");
      }

      if (
        existingFile.data.results.length <
          addedFileInTheFolder.data.results.length &&
        !isFolder
      ) {
        openToast("success", "File uploaded successfully");
      }

      return {
        ...state,
        filesDataBreadcrumbs: state.filesDataBreadcrumbs.map((breadcrumb) =>
          breadcrumb.folder === existingFile.folder && !isFolder
            ? addedFileInTheFolder
            : breadcrumb
        ),
        folderDataBreadcrumbs: state.folderDataBreadcrumbs.map((breadcrumb) =>
          breadcrumb.folder === existingFile.folder && !!isFolder
            ? newlyAddedFolder
            : breadcrumb
        ),
        loading: false,
      };
    }
    case RESET_FILES_AND_FOLDERS_BREADCRUMBS: {
      return { ...state, filesDataBreadcrumbs: [], folderDataBreadcrumbs: [] };
    }
    case KEEP_FILES_AND_FOLDERS_BREADCRUMBS: {
      const { folderIds } = action.payload;
      const filteredData = state.folderDataBreadcrumbs.filter((item) =>
        folderIds.includes(item.folder?.toString())
      );
      return { ...state, folderDataBreadcrumbs: filteredData };
    }
    case DELETE_FOLDER_FILES: {
      const { id } = action.payload;
      const { search } = getUrlProperties();
      const folder = getDataFromParams(search, "folder");
      const foldersList = folder?.split("-");
      const currentFolderId = foldersList[foldersList?.length - 1];
      const folderContainingFile = state?.filesDataBreadcrumbs?.find(
        (item) => String(item.folder) === currentFolderId
      );
      const requiredFiles = folderContainingFile?.data?.results.filter(
        (item) => String(item.id) !== id
      );

      const fileToBeDeleted = folderContainingFile?.data?.results.find(
        (item) => String(item.id) === id
      );
      openToast("success", `${fileToBeDeleted.title} successfully deleted .`);

      return {
        ...state,
        filesDataBreadcrumbs: state.filesDataBreadcrumbs.map((item) =>
          String(item.folder) === currentFolderId
            ? {
                ...item,
                data: { ...item.data, results: requiredFiles },
              }
            : item
        ),
        loading: false,
      };
    }
    case REMOVE_DELETED_FOLDER_FROM_PARENT: {
      const { parentFolderId, folderId } = action.payload;
      const parentFolderOfDeletedFolder = state?.folderDataBreadcrumbs?.find(
        (item) => String(item.folder) === parentFolderId
      );
      const requiredParentFolder = {
        ...parentFolderOfDeletedFolder,
        data: {
          ...parentFolderOfDeletedFolder.data,
          results: parentFolderOfDeletedFolder.data.results?.filter(
            (folder) => String(folder.id) !== folderId
          ),
        },
      };
      return {
        ...state,

        folderDataBreadcrumbs: state.folderDataBreadcrumbs.map((item) =>
          String(item.folder) === parentFolderId ? requiredParentFolder : item
        ),
      };
    }
    case REMOVE_DELETED_FOLDER_FROM_AGENCY: {
      const { agencyId, folderId } = action.payload;

      state.agenciesList.results = state.agenciesList.results.map((item) =>
        String(item.id) === agencyId ? { ...item } : { ...item }
      );
      return {
        ...state,
        currentAgencyFolders: {
          ...state.currentAgencyFolders,
          results: state.currentAgencyFolders.results.filter(
            (folder) => String(folder.id) !== folderId
          ),
        },
      };
    }
    case SET_DAM_FOLDERS: {
      return {
        ...state,
        foldersOfDam: [...state.foldersOfDam, action.payload],
        damFoldersBreadcrumbs: action.payload?.parent?.id
          ? [...state.damFoldersBreadcrumbs, action.payload.parent]
          : state.damFoldersBreadcrumbs,
        loading: false,
      };
    }
    case RESET_DAM_FOLDERS_BREADCRUMBS: {
      return {
        ...state,
        damFoldersBreadcrumbs: [],
        foldersOfDam: [state.foldersOfDam[0]],
      };
    }
    case REMOVE_FOLDERS_FROM_BREADCRUMBS_WITH_INDEX: {
      return {
        ...state,
        damFoldersBreadcrumbs: state.damFoldersBreadcrumbs.slice(
          0,
          action.payload + 1
        ),
        foldersOfDam: state.foldersOfDam.slice(0, action.payload + 2),
      };
    }
    case ADD_NEW_FOLDER_FOR_COPY: {
      // Add newFolder to the results of the last index in folders
      state.foldersOfDam[state.foldersOfDam.length - 1].results.push(
        action.payload
      );
      return { ...state };
    }
    case RENAME_THE_NEWLY_ADDED_FOLDER_FOR_COPY: {
      const { id, name } = action.payload;
      return {
        ...state,
        foldersOfDam: state.foldersOfDam.map((folder, index) => {
          if (index === state.foldersOfDam.length - 1) {
            return {
              ...folder,
              results: folder.results.map((item) =>
                item.id === id ? { ...item, name } : item
              ),
            };
          }
          return folder;
        }),
      };
    }

    case ADD_NEW_FOLDER_INTO_DAM_BREADCRUMBS: {
      return {
        ...state,
        foldersOfDam: [
          ...state.foldersOfDam,
          { parent: action.payload, results: [] },
        ],
        damFoldersBreadcrumbs: [...state.damFoldersBreadcrumbs, action.payload],
      };
    }
    default: {
      return state;
    }
  }
};
