import React, { FC, useCallback, useEffect, useState } from "react";
import { Button } from "pyxis-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgencyShareableKeyAction,
  postAgencyShareableKeyAction,
} from "../../../../store/actions/agencyFile";
import { createShareableAgencyURL } from "../../utils";

import { Checkbox, Input, Modal } from "antd";

import { Agencies, AgencyFileDropState } from "../../../../store/types";

import { openToast } from "../../../../Toasts";

import cancelButton from "../../../../assets/svg/cancelModalIcon.svg";
import copyIcon from "../../../../assets/svg/copy_agency_link_icon.svg";

import "./index.scss";

interface ShareAgencyModalProps {
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  agency: Agencies;
}

const ShareAgencyModal: FC<ShareAgencyModalProps> = ({
  isModalOpen,
  setModalOpen,
  agency,
}) => {
  const dispatch = useDispatch();
  const { agencyToBeShared } = useSelector(
    (state: { agencyFileDrop: AgencyFileDropState }) => state.agencyFileDrop
  );

  const [isPasswordProtected, setIsPasswordProtected] = useState<boolean>(
    agencyToBeShared.password_flag
  );

  useEffect(() => {
    if (isPasswordProtected !== agencyToBeShared?.password_flag) {
      setIsPasswordProtected(agencyToBeShared.password_flag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyToBeShared.password_flag]);

  const agencyPublicURL = createShareableAgencyURL(
    `${window.location.origin}#/cocreate/shared-agency`,
    agencyToBeShared
  );
  useEffect(() => {
    if (!!isModalOpen) {
      dispatch(
        getAgencyShareableKeyAction({
          password_flag: isPasswordProtected,
          agency: agency.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const handleCopy = (id: string) => {
    const inputField = document.getElementById(id) as HTMLInputElement;
    if (inputField) {
      navigator.clipboard
        .writeText(inputField.value)
        .then(() => {
          openToast("success", "Text copied to clipboard.");
        })
        .catch((error) => {
          console.error("Failed to copy text: ", error);
        });
    }
  };
  const handleCancelButton = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handleSetPassword = useCallback(() => {
    setIsPasswordProtected(!isPasswordProtected);
  }, [isPasswordProtected]);

  const handleGenerateLink = useCallback(() => {
    dispatch(
      postAgencyShareableKeyAction({
        password_flag: isPasswordProtected,
        agency: agency.id,
      })
    );
  }, [agency.id, dispatch, isPasswordProtected]);

  return (
    <Modal
      centered
      visible={isModalOpen}
      footer={null}
      className="share-agency-modal"
      onCancel={handleCancelButton}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          className="modal-title"
          style={{ display: "flex", alignItems: "center" }}
        >
          Share "{agency.agency_name}"
        </div>
        <div style={{ height: "3rem" }}>
          <img
            src={cancelButton}
            alt="cancel button"
            style={{ cursor: "pointer" }}
            onClick={handleCancelButton}
          />
        </div>
      </div>
      <div className="modal-desc ">
        Let people upload files your folder structure
      </div>

      {!!agencyToBeShared.shareable_link_key ? (
        <>
          <div className="input-title">Direct link</div>
          <div className="input-wrapper">
            <Input
              id="shareable-link"
              className="input-field input-data__link"
              value={agencyPublicURL}
            />
            <img
              src={copyIcon}
              alt="copy Icon"
              style={{ height: "3.6rem", cursor: "pointer" }}
              onClick={() => handleCopy("shareable-link")}
            />
          </div>
          {!!isPasswordProtected ? (
            <>
              <div className="input-title">Password</div>
              <div className="input-wrapper">
                <Input
                  id="shareable-pass"
                  className="input-field input-data__password"
                  value={agencyToBeShared.password}
                />
                <img
                  src={copyIcon}
                  alt="copy Icon"
                  style={{ height: "3.6rem", cursor: "pointer" }}
                  onClick={() => handleCopy("shareable-pass")}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="settings-wrapper">Settings</div>
          <Checkbox
            className="password-checkbox"
            checked={isPasswordProtected}
            onChange={handleSetPassword}
          >
            Protect Link with password
          </Checkbox>
          {/* <Checkbox
            className="password-checkbox"
            checked={!isPasswordProtected}
            onChange={handleSetPassword}
          >
            Create Link without password
          </Checkbox> */}
          <Button
            btnsize="md"
            type="primary"
            className="primary submit"
            style={{ alignSelf: "flex-end" }}
            onClick={handleGenerateLink}
          >
            Generate Link
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default ShareAgencyModal;
