import React, { FC, useCallback, useState } from "react";

import { useDispatch } from "react-redux";

import { Input, Tooltip } from "antd";
import { MenuItem, Dropdown, Menu } from "pyxis-ui-kit";

import {
  deleteAgencyFolder,
  downloadFolderAction,
  renameAgencyFolder,
} from "../../../../store/actions/agencyFile";
import { AgencyFolder } from "../../../../store/types";

import DeleteResourceModal from "../DeleteResourceModal";

import { getDataFromParams } from "../../utils";
import { getUrlProperties } from "../../../../utilities/common-function";

import CopyResourceModal from "../CopyResourceModal";

import renameIcon from "../../../../assets/svg/renameIcon.svg";
import deleteIcon from "../../../../assets/svg/delete_agency.svg";
import downloadIcon from "../../../../assets/svg/downloadIcon.svg";
import dropdownIcon from "../../../../assets/svg/dropdown-icon.svg";
import folderIcon from "../../../../assets/svg/agency-folder-icon.svg";
import copyFolderIcon from "../../../../assets/svg/CopyFolderIcon.svg";
import { DESIGN_URI } from "../../../../utilities/paths";
import { agenciesFoldersAPI } from "../../../../MappedAPI/MappedAPI";

interface AgencyFolderItemProps {
  folder: AgencyFolder;
  index: number;
  handleFolderClick: Function;
  isForPublic?: boolean;
}

const AgencyFolderItem: FC<AgencyFolderItemProps> = ({
  folder,
  index,
  handleFolderClick,
  isForPublic = false,
}) => {
  const dispatch = useDispatch();
  const { search } = getUrlProperties();
  const folders = getDataFromParams(search, "folder");

  const [isDeleteAgencyModalOpen, setIsDeleteAgencyModalOpen] = useState<
    boolean
  >(false);
  const [isCopyFolderModalOpen, setIsCopyFolderModalOpen] = useState<boolean>(
    false
  );
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false); // State to track edit mode
  const [folderName, setFolderName] = useState<string>(folder.name); // Editable folder name
  const [selectedFolder, setSelectedFolders] = useState<AgencyFolder | null>(
    null
  );
  const handleAgencyFolderDelete = useCallback(() => {
    dispatch(
      deleteAgencyFolder({
        id: String(selectedFolder.id),
        agencyId: selectedFolder.agency_id,
      })
    );
    setIsDeleteAgencyModalOpen(false);
  }, [dispatch, selectedFolder]);

  const downloadFile = (url) => {
    const a = document.createElement("a");
    a.href = url;

    a.setAttribute("download", "");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleMenuClick = useCallback((key: string, folder: AgencyFolder) => {
    setSelectedFolders(folder);
    switch (key) {
      case "copyFolder":
        setIsCopyFolderModalOpen(true);
        break;

      case "downloadFolder":
        const string = `${DESIGN_URI}${agenciesFoldersAPI}${
          folder.agency_id
        }/${String(folder.id)}/download/`;
        // downloadFile(string);
        console.log("🚀 ~ handleMenuClick ~ string:", string);
        // dispatch(
        //   downloadFolderAction({
        //     agency_id: folder.agency_id,
        //     folder_id: String(folder.id),
        //   })
        // );
        break;

      case "renameFolder":
        setFolderName(folder.name);
        setIsEditing(true);
        break;

      case "deleteFolder":
        setIsDeleteAgencyModalOpen(true);
        break;

      default:
        return;
    }

    setActiveDropdown(null); // Close dropdown after selection
  }, []);

  const handleRenameSubmit = useCallback(() => {
    if (folderName !== folder.name && !!folderName.length) {
      dispatch(renameAgencyFolder({ id: String(folder.id), name: folderName }));
    }
    setIsEditing(false);
  }, [dispatch, folder, folderName]);

  const menu = (folder: AgencyFolder) => (
    <Menu
      style={{ width: "22rem", borderRadius: "1rem" }}
      onClick={(e) => handleMenuClick(String(e.key), folder)} // Cast key to string
    >
      <MenuItem key="copyFolder" className="dropdown__item">
        <img src={copyFolderIcon} alt="Copy folder icon" />
        Copy to my files
      </MenuItem>
      <MenuItem key="downloadFolder" className="dropdown__item">
        <img src={downloadIcon} alt="Download folder icon" />
        Download folder
      </MenuItem>
      {!!folders ? (
        <></>
      ) : (
        <MenuItem key="renameFolder" className="dropdown__item">
          <img src={renameIcon} alt="Rename folder icon" />
          Rename
        </MenuItem>
      )}
      <MenuItem
        key="deleteFolder"
        className="dropdown__item dropdown__item--delete"
      >
        <img src={deleteIcon} alt="Delete folder icon" />
        Delete
      </MenuItem>
    </Menu>
  );
  return (
    <>
      <div
        className="folder-data"
        key={`folder.id`}
        onDoubleClick={() => handleFolderClick(folder)}
      >
        <div className="folder_info_wrapper">
          <img src={folderIcon} alt="Folder Icon" />
          <div className="folder_info">
            {isEditing ? (
              <Input
                className="input-field"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                onBlur={handleRenameSubmit}
                onPressEnter={handleRenameSubmit} // Save the name on pressing Enter
                autoFocus
                style={{ fontSize: "1.2rem !important" }}
              />
            ) : (
              <Tooltip
                placement="bottomRight"
                title={folder.name}
                key={folder.id + folder.name}
              >
                <div className="folder_name">
                  {folder.name.length > 45
                    ? `${folder.name.slice(0, 45)}...`
                    : folder.name}
                </div>
              </Tooltip>
            )}

            <div className="total_folders">
              {folder.content_count || 0} documents
            </div>
          </div>
        </div>
        {!isForPublic && (
          <Dropdown
            overlay={menu(folder)}
            visible={activeDropdown === index} // Check if this dropdown should be visible
            onVisibleChange={(visible) => {
              setActiveDropdown(visible ? index : null); // Show dropdown only for the active folder
            }}
            trigger={["click"]} // Opens dropdown on click
            placement="bottomRight"
          >
            <div style={{ cursor: "pointer" }}>
              <img src={dropdownIcon} alt="DropDown Icon" />
            </div>
          </Dropdown>
        )}
      </div>

      <DeleteResourceModal
        isModalOpen={isDeleteAgencyModalOpen}
        setModalOpen={setIsDeleteAgencyModalOpen}
        modalTitle={"Delete Folder?"}
        modalDesc={"Are you sure you want to delete this folder."}
        handleDeleteClick={handleAgencyFolderDelete}
      />

      <CopyResourceModal
        isModalOpen={isCopyFolderModalOpen}
        setModalOpen={setIsCopyFolderModalOpen}
        itemIdToBeCopied={selectedFolder}
        isFolder={true}
      />
    </>
  );
};

export default AgencyFolderItem;
