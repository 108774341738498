import React, { useCallback, useState } from "react";
import {
  buildParentHierarchyJson,
  getDataFromParams,
} from "../../../AgencyFileDrop/utils";
import { getUrlProperties } from "../../../../utilities/common-function";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAgencyFolders,
  keepFilesAndFoldersBreadcrumbsAction,
  resetFilesAndFoldersBreadcrumbsAction,
} from "../../../../store/actions/agencyFile";
import { AgencyFileDropState } from "../../../../store/types";
import { Dropdown, Menu, MenuItem } from "pyxis-ui-kit";

const AgencyFoldersBreadcrumbs = ({ setIsURLContainsFolders, agency_id }) => {
  const dispatch = useDispatch();
  const state = useSelector(
    (state: { agencyFileDrop: AgencyFileDropState }) => ({
      ...state.agencyFileDrop,
    })
  );

  const {
    folderDataBreadcrumbs,
  } = state;
  const [activeDropdown, setActiveDropdown] = useState<boolean>(false);

  const { baseUrl, search } = getUrlProperties();
  const folders = getDataFromParams(search, "folder");
  const foldersList = folders?.split("-");
  const foldersBreadCrumbs = buildParentHierarchyJson(
    folderDataBreadcrumbs,
    foldersList
  );

  const handleAgencyFileDropClick = useCallback(() => {
    if (!folders) {
      return;
    }
    const newurl = baseUrl + "?" + search.replace(/&?folder=[^&]*/, "");
    window.history.pushState({ path: newurl }, "", newurl);
    setIsURLContainsFolders(false);
    dispatch(resetFilesAndFoldersBreadcrumbsAction());
    dispatch(fetchAgencyFolders({ agency_id }));
  }, [agency_id, baseUrl, dispatch, folders, search, setIsURLContainsFolders]);

  const handleFolderBreadcrumbClick = useCallback(
    (folderId) => {
      let newurl = baseUrl + "?" + search.replace(/&?folder=[^&]*/, "");
      const index = foldersList.indexOf(String(folderId)); // Find the index of the folderId
      const updatedFolderIds =
        index !== -1 ? foldersList.slice(0, index + 1) : [];
      const foldersUrl = updatedFolderIds.join("-");
      newurl = newurl + `&folder=${foldersUrl}`;
      window.history.pushState({ path: newurl }, "", newurl);
      dispatch(
        keepFilesAndFoldersBreadcrumbsAction({ folderIds: updatedFolderIds })
      );
      setActiveDropdown(false);
    },
    [baseUrl, dispatch, foldersList, search]
  );

  const breadcrumbsMenu = () => {
    return (
      <Menu style={{ borderRadius: "1rem" }}>
        {foldersBreadCrumbs
          .slice(2, foldersBreadCrumbs.length - 1)
          .map((folder) => (
            <MenuItem
              key={folder.id}
              onClick={() => handleFolderBreadcrumbClick(folder.id)}
            >
              {folder.name}
            </MenuItem>
          ))}
      </Menu>
    );
  };
  return (
    <div>
      <div
        className="header-title"
        style={{
          fontSize: "2rem",
          display: "flex",
          flexDirection: "row",
          fontWeight: !!foldersList?.length ? 400 : 600,
          color: !!foldersList?.length ? "#5C6674" : "",
          cursor: !!foldersList?.length ? "pointer" : "",
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            onClick={handleAgencyFileDropClick}
            style={{ cursor: !!foldersList?.length ? "pointer" : "" }}
          >
            Agency File Drop
          </div>
          {!!foldersBreadCrumbs?.length && (
            <>
              {foldersBreadCrumbs.length > 3 ? (
                <>
                  <div style={{ display: "flex" }}>
                    <span className="path">
                      &nbsp;&nbsp;{" > "}&nbsp;&nbsp;
                    </span>
                    <div
                      style={{
                        fontWeight: 400,
                        color: "#5C6674",
                      }}
                      onClick={() =>
                        handleFolderBreadcrumbClick(foldersBreadCrumbs[0].id)
                      }
                    >
                      {foldersBreadCrumbs[0].name}
                    </div>
                  </div>

                  {/* Show the second breadcrumb */}
                  <div style={{ display: "flex" }}>
                    <span className="path">
                      &nbsp;&nbsp;{" > "}&nbsp;&nbsp;
                    </span>
                    <div
                      style={{
                        fontWeight: 400,
                        color: "#5C6674",
                      }}
                      onClick={() =>
                        handleFolderBreadcrumbClick(foldersBreadCrumbs[1].id)
                      }
                    >
                      {foldersBreadCrumbs[1].name}
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <span className="path">
                      &nbsp;&nbsp;{" > "}&nbsp;&nbsp;
                    </span>

                    <Dropdown
                      overlay={breadcrumbsMenu}
                      visible={activeDropdown} // Check if this dropdown should be visible
                      onVisibleChange={(visible) => {
                        setActiveDropdown(visible); // Show dropdown only for the active folder
                      }}
                      trigger={["click"]} // Opens dropdown on click
                      // placement="bottomRight"
                    >
                      <div
                        style={{
                          cursor: "pointer",
                          fontWeight: 400,
                          color: "#5C6674",
                        }}
                      >
                        ...
                      </div>
                    </Dropdown>
                  </div>

                  <div style={{ display: "flex" }}>
                    <span className="path">
                      &nbsp;&nbsp;{" > "}&nbsp;&nbsp;
                    </span>
                    <div
                      style={{
                        fontWeight: 600,
                        color: "#000",
                      }}
                      onClick={() =>
                        handleFolderBreadcrumbClick(
                          foldersBreadCrumbs[foldersBreadCrumbs.length - 1].id
                        )
                      }
                    >
                      {foldersBreadCrumbs[foldersBreadCrumbs.length - 1].name}
                    </div>
                  </div>
                </>
              ) : (
                foldersBreadCrumbs.map((folder, index) => (
                  <div key={folder.id} style={{ display: "flex" }}>
                    <span className="path">
                      &nbsp;&nbsp;{" > "}&nbsp;&nbsp;
                    </span>
                    <div
                      style={{
                        fontWeight:
                          index + 1 === foldersBreadCrumbs?.length ? 600 : 400,
                        color:
                          index + 1 === foldersBreadCrumbs?.length
                            ? "#000"
                            : "#5C6674",
                      }}
                      onClick={() => handleFolderBreadcrumbClick(folder.id)}
                    >
                      {folder.name}
                    </div>
                  </div>
                ))
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgencyFoldersBreadcrumbs;
