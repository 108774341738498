import React, { FC, useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Button, Layout } from "pyxis-ui-kit";
import { Tooltip } from "antd";

import {
  createNewAgency,
  createNewFolder,
  deleteAgencyFolder,
  fetchAgencyData,
  fetchAgencyFolders,
  fetchAgencyListAction,
  getAgencyFilesList,
  keepFilesAndFoldersBreadcrumbsAction,
  removeDeletedFolderFromAgency,
  removeDeletedFolderFromParent,
  resetAgencyFileDropAction,
  resetFilesAndFoldersBreadcrumbsAction,
} from "../../store/actions/agencyFile";
import { Agencies, AgencyFileDropState, RolesState } from "../../store/types";

import {
  getUrlProperties,
  permissionDeniedClick,
} from "../../utilities/common-function";

import AgencyHeader from "./components/AgencyHeader";
import CreateResourceModal from "./components/CreateResourceModal";
import { FullScreenSpinner } from "../../components/shared/Spinner";

import emptyAgency from "../../assets/img/emptyAgency.jpg";
import emptyFoldersIcon from "../../assets/svg/empty_folders.svg";
import activeAgencyIcon from "../../assets/svg/ActiveAgencyIcon.svg";
import backNavigationIcon from "../../assets/svg/BackArrowAgencyFiles.svg";
import copyFolderIcon from "../../assets/svg/CopyFolderIcon.svg";
import downloadIcon from "../../assets/svg/downloadIcon.svg";
import deleteIcon from "../../assets/svg/delete_agency.svg";

import "./index.scss";
import AgencyFolderItem from "./components/AgencyFolderItem";
import { getDataFromParams } from "./utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import AgencyFoldersBreadcrumbs from "../PublicAgencyView/components/AgencyFoldersBreadcrumbs";
import AgencyFileView from "../AgencyFileView";
import DeleteResourceModal from "./components/DeleteResourceModal";

const AgencyFileDrop: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const brandPermissions = useSelector(
    (state: { roles: RolesState }) => state.roles?.brandPermissions
  );
  const workspace = useSelector(({ roles }: any) => roles.selectedProductId);
  const {
    agenciesList,
    loading,
    currentAgencyFolders,
    defaultSelectedAgency,
    isFetchingFolders,
  } = useSelector(
    (state: { agencyFileDrop: AgencyFileDropState }) => state.agencyFileDrop
  );
  const hasAddWorkspacePermission = brandPermissions?.includes(
    "add-delete-product"
  );
  const { baseUrl, search } = getUrlProperties();
  const selectedAgencyId = getDataFromParams(search, "agency");
  const folders = getDataFromParams(search, "folder");
  const foldersList = folders?.split("-");

  const [isNewAgencyModalOpen, setNewAgencyModalOpen] = useState<boolean>(
    false
  );

  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState<
    boolean
  >(false);

  const [titleText, setTitleText] = useState<string>("");
  const [selectedAgency, setSelectedAgency] = useState<Agencies | null>(null);
  const [isURLContainsFolders, setIsURLContainsFolders] = useState<boolean>(
    !!folders
  );
  const [hasMoreAgencies, setHasMoreAgencies] = useState<boolean>(
    agenciesList.current_page <= agenciesList.pages
  );
  const [hasMoreFolders, setHasMoreFolders] = useState<boolean>(
    currentAgencyFolders.results.length < currentAgencyFolders.count
  );
  const [isDeleteFOlderModalOpen, setIsDeleteFolderModalOpen] = useState<
    boolean
  >(false);

  useEffect(() => {
    setIsURLContainsFolders(!!folders);
  }, [folders]);

  const handleAgencyClick = useCallback(
    (agency) => {
      let newurl = baseUrl + `?agency=${agency.id}`;
      window.history.pushState({ path: newurl }, "", newurl);
      dispatch(
        fetchAgencyFolders({ agency_id: agency.id, isAgencyChanged: true })
      );
      setSelectedAgency(agency);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baseUrl, dispatch, currentAgencyFolders]
  );

  useEffect(() => {
    setHasMoreFolders(
      currentAgencyFolders.results.length < currentAgencyFolders.count
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleAgencyClick]);

  const handleBackAction = useCallback(() => {
    history.push("/");
    dispatch(resetAgencyFileDropAction());
  }, [dispatch, history]);

  useEffect(() => {
    if (!hasAddWorkspacePermission) {
      permissionDeniedClick();
      setTimeout(() => {
        handleBackAction();
      }, 2000);
      return;
    } else {
    dispatch(fetchAgencyListAction({ workspace }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace, isURLContainsFolders]);

  useEffect(() => {
    if (!!isURLContainsFolders) {
      for (let i = 0; i < foldersList.length; i++) {
        const folder = foldersList[i];
        dispatch(
          fetchAgencyFolders({ agency_id: selectedAgencyId, parent: folder })
        );

        dispatch(
          getAgencyFilesList({
            agency_id: selectedAgencyId,
            folders: folder,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !!defaultSelectedAgency &&
      defaultSelectedAgency?.workspace === workspace
    ) {
      if (!selectedAgencyId) {
        let newurl = baseUrl + `?agency=${defaultSelectedAgency.id}`;
        window.history.pushState({ path: newurl }, "", newurl);
      }
      setSelectedAgency(defaultSelectedAgency);
      dispatch(
        fetchAgencyFolders({
          agency_id: defaultSelectedAgency?.id,
          isAgencyChanged: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedAgency]);

  const handleNewAgencyModalOpen = useCallback(
    () => setNewAgencyModalOpen(true),
    []
  );

  const handleNewFolderModalOpen = useCallback(
    () => setIsCreateFolderModalOpen(true),
    []
  );

  const handleCreateAgency = useCallback(
    (title) => {
      const newAgencyPayload = {
        agency_name: title,
        workspace,
      };
      dispatch(createNewAgency(newAgencyPayload));
      setNewAgencyModalOpen(false);
      setTitleText("");
      window.location.href = baseUrl;
    },
    [baseUrl, dispatch, workspace]
  );

  const handleCreateFolder = useCallback(
    (title) => {
      const newFolderPayload = {
        name: title,
        agency_id: selectedAgency?.id,
      };
      dispatch(createNewFolder(newFolderPayload));
      setIsCreateFolderModalOpen(false);
      setTitleText("");
      window.history.pushState({ path: baseUrl }, "", baseUrl);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, selectedAgency]
  );

  const fetchMoreAgencies = useCallback(() => {
    if (agenciesList.current_page >= agenciesList.pages) {
      setHasMoreAgencies(false);
      return;
    }

    const requiredData = {
      workspace,
      page: agenciesList.current_page + 1,
    };
    dispatch(fetchAgencyListAction(requiredData));
  }, [agenciesList, dispatch, workspace]);

  const fetchMoreFolders = useCallback(() => {
    if (currentAgencyFolders.current_page >= currentAgencyFolders.pages) {
      setHasMoreFolders(false);
      return;
    }

    const requiredData = {
      agency_id: selectedAgency.id,
      page: currentAgencyFolders.current_page + 1,
    };
    dispatch(fetchAgencyFolders(requiredData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAgencyFolders, currentAgencyFolders, dispatch, selectedAgency]);

  const handleFolderClick = useCallback(
    (folder) => {
      let newurl = baseUrl + (search ? `?${search}&folder=${folder.id}` : "");
      window.history.pushState({ path: newurl }, "", newurl);
      history.push(search ? `?${search}&folder=${folder.id}` : "");
      setIsURLContainsFolders(true);
      dispatch(
        getAgencyFilesList({ agency_id: selectedAgencyId, folders: folder.id })
      );
      dispatch(
        fetchAgencyFolders({ agency_id: selectedAgencyId, parent: folder.id })
      );
    },
    [baseUrl, dispatch, history, search, selectedAgencyId]
  );

  const handleDeleteFolderModal = () => setIsDeleteFolderModalOpen(true);

  const handleFolderDelete = useCallback(() => {
    const currentFolderId = foldersList[foldersList.length - 1];
    let newurl = baseUrl + "?" + search.replace(/&?folder=[^&]*/, "");
    dispatch(
      deleteAgencyFolder({
        id: String(currentFolderId),
        agencyId: selectedAgencyId,
      })
    );
    if (foldersList?.length === 1) {
      window.history.pushState({ path: newurl }, "", newurl);
      history.push("?" + search.replace(/&?folder=[^&]*/, ""));
      setIsURLContainsFolders(false);
      dispatch(resetFilesAndFoldersBreadcrumbsAction());
      dispatch(
        fetchAgencyFolders({
          agency_id: selectedAgencyId,
          folderToBeRemoved: currentFolderId,
        })
      );
      dispatch(
        removeDeletedFolderFromAgency({
          agencyId: selectedAgencyId,
          folderId: currentFolderId,
        })
      );
    } else {
      const updatedFolderIds = foldersList.slice(0, -1);
      const foldersUrl = updatedFolderIds.join("-");
      newurl = newurl + `&folder=${foldersUrl}`;
      window.history.pushState({ path: newurl }, "", newurl);
      history.push(`&folder=${foldersUrl}`);

      dispatch(
        keepFilesAndFoldersBreadcrumbsAction({ folderIds: updatedFolderIds })
      );
      dispatch(
        removeDeletedFolderFromParent({
          parentFolderId: updatedFolderIds[updatedFolderIds.length - 1],
          folderId: currentFolderId,
        })
      );
    }

    setIsDeleteFolderModalOpen(false);
  }, [baseUrl, dispatch, foldersList, history, search, selectedAgencyId]);

  return !hasAddWorkspacePermission ? (
    <></>
  ) : (
    <>
      <div style={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
        {!!isURLContainsFolders ? (
          <>
            <Layout>
              <div
                className="agency-files-header"
                style={{
                  height: "8.4rem",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <AgencyFoldersBreadcrumbs
                  setIsURLContainsFolders={setIsURLContainsFolders}
                  agency_id={selectedAgencyId}
                />
                <div
                  className="agency-folder-header"
                  style={{ paddingRight: "2rem" }}
                >
                  <div className="agency-folder-header__content">
                    <div className="agency-folder-header__actions">
                      <Button
                        btnsize="sm"
                        className="agency-folder-header__button"
                        // onClick={handleCreateFolderModal}
                      >
                        <span className="agency-folder-header__button-text">
                          <img src={copyFolderIcon} alt="copy folder" />
                          &nbsp;&nbsp;&nbsp; Copy this folder to my files
                        </span>
                      </Button>
                      <Button
                        btnsize="sm"
                        className="agency-folder-header__button"
                        // onClick={handleDeleteAgencyModal}
                      >
                        <span className="agency-folder-header__button-text">
                          <img src={downloadIcon} alt="download folder" />
                          &nbsp;&nbsp;&nbsp;Download this folder
                        </span>
                      </Button>
                      <Button
                        btnsize="sm"
                        className="agency-folder-header__button"
                        onClick={handleDeleteFolderModal}
                      >
                        <span className="agency-folder-header__button-text ">
                          <img src={deleteIcon} alt="delete folder" />
                          &nbsp;&nbsp;Delete this folder
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Layout>

            <AgencyFileView agency_id={selectedAgencyId} isForPublic={false} />
          </>
        ) : (
          <>
            <Layout>
              <div className="agency-files-header">
                <img
                  src={backNavigationIcon}
                  alt="backNavigationIcon"
                  className="agency-back-icon"
                  onClick={handleBackAction}
                />
                <div className="header-title">Agency File Drop</div>
              </div>
            </Layout>
            <div className="agency-homepage-wrapper">
              {loading && <FullScreenSpinner />}
              {!agenciesList?.results?.length ? (
                <div className="empty-agency-div flex-items-content-center">
                  <img src={emptyAgency} alt="Empty Agency" />
                  <div className="empty-content-title">
                    You don't have any Agency File Drops created
                  </div>
                  <div className="empty-agencies-content-desc">
                    Create a securely shareable folder structure
                  </div>
                  <Button
                    btnsize="lg"
                    type="primary"
                    className="primary add-resource-button"
                    onClick={handleNewAgencyModalOpen}
                  >
                    <span className={"flex"}>+ Create Agency File Drop</span>
                  </Button>
                </div>
              ) : (
                <div className="layout-container">
                  <div className="agency-panel">
                    <Button
                      btnsize="lg"
                      className="agency-panel__add-button"
                      onClick={handleNewAgencyModalOpen}
                    >
                      <span className="button__text">
                        +&nbsp;&nbsp;&nbsp;New Agency File Drop
                      </span>
                    </Button>
                    <div className="agency-panel_list" id="agency-panel_list">
                      <InfiniteScroll
                        dataLength={agenciesList.results.length}
                        next={fetchMoreAgencies}
                        hasMore={hasMoreAgencies}
                        loader={""}
                        scrollableTarget="agency-panel_list"
                      >
                        {Array.from(
                          new Map(
                            agenciesList.results.map((item) => [item.id, item])
                          ).values() // it will remove the duplicate data from the agencies if we select the agency which is not in the default get agencies api call
                        ).map((agency) => (
                          <div
                            className={
                              agency.id === selectedAgency?.id
                                ? "agency-panel__info agency-panel__selectedAgency"
                                : "agency-panel__info"
                            }
                            id={
                              agency.id === selectedAgency?.id
                                ? "selected-agency"
                                : ""
                            }
                            onClick={() => handleAgencyClick(agency)}
                            key={`${agency.id + agency.agency_name}`}
                          >
                            <div className="agency-panel__details">
                              <Tooltip
                                placement="bottomRight"
                                title={agency.agency_name}
                              >
                                <div className="agency-panel__name">
                                  {agency.agency_name.length > 34
                                    ? `${agency.agency_name.slice(0, 30)}...`
                                    : agency.agency_name}
                                </div>
                              </Tooltip>
                              <div className="agency-panel__folder-count">
                                {agency.folders_count > 0
                                  ? agency.folders_count
                                  : 0}{" "}
                                Folders
                              </div>
                            </div>
                            {agency.id === selectedAgency?.id ? (
                              <div className="agency-panel__icon">
                                <img
                                  src={activeAgencyIcon}
                                  alt="active agency icon"
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}
                      </InfiniteScroll>
                    </div>
                  </div>

                  <div className="main-content">
                    {selectedAgency ? (
                      <>
                        <AgencyHeader
                          agency={selectedAgency}
                          setAgency={setSelectedAgency}
                          titleText={titleText}
                          setTitleText={setTitleText}
                          handleCreateFolderAction={handleCreateFolder}
                          isCreateFolderModalOpen={isCreateFolderModalOpen}
                          setIsCreateFolderModalOpen={
                            setIsCreateFolderModalOpen
                          }
                        />
                        {!!currentAgencyFolders.results.length ? (
                          <div
                            className="folders_wrapper"
                            id="folders_wrapper"
                            style={{ maxHeight: "calc(100% - 13.4rem)" }}
                          >
                            <InfiniteScroll
                              dataLength={currentAgencyFolders.results.length}
                              next={fetchMoreFolders}
                              hasMore={hasMoreFolders}
                              loader={null}
                              scrollableTarget="folders_wrapper"
                              scrollThreshold={0.9}
                            >
                              <div className="folders-content">
                                {currentAgencyFolders.results.map(
                                  (folder, index) => (
                                    <AgencyFolderItem
                                      folder={folder}
                                      index={index}
                                      handleFolderClick={handleFolderClick}
                                    />
                                  )
                                )}
                                {!!isFetchingFolders ? (
                                  <>
                                    <div className="folder-data" />
                                    <div className="folder-data" />
                                    <div className="folder-data" />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </InfiniteScroll>
                          </div>
                        ) : (
                          /* UI when no folder is there in agency */
                          <div className="no_folders flex-items-content-center">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: "2rem",
                                paddingBottom: "10rem",
                              }}
                            >
                              <img src={emptyFoldersIcon} alt="Empty Folders" />
                              <div className="empty-content-title">
                                No users have been added to this Workspace
                              </div>
                              <Button
                                btnsize="md"
                                type="primary"
                                className="primary add-resource-button"
                                onClick={handleNewFolderModalOpen}
                              >
                                <span className={"flex"}>+ Create Folder</span>
                              </Button>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <CreateResourceModal
        isModalOpen={isNewAgencyModalOpen}
        setModalOpen={setNewAgencyModalOpen}
        modalTitle={"Create new Agency File Drop"}
        modalDesc={"Name your Agency File Drop"}
        text={titleText}
        setText={setTitleText}
        handleCreateButton={handleCreateAgency}
      />

      <CreateResourceModal
        isModalOpen={isCreateFolderModalOpen}
        setModalOpen={setIsCreateFolderModalOpen}
        modalTitle={"New folder"}
        modalDesc={"Name your folder"}
        text={titleText}
        setText={setTitleText}
        handleCreateButton={handleCreateFolder}
      />

      <DeleteResourceModal
        isModalOpen={isDeleteFOlderModalOpen}
        setModalOpen={setIsDeleteFolderModalOpen}
        modalTitle={"Delete Folder?"}
        modalDesc={"Are you sure you want to delete this folder."}
        handleDeleteClick={handleFolderDelete}
      />
    </>
  );
};

export default AgencyFileDrop;
